import { Button, SvgIcon } from "@mui/material";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";

export default function TransfererButtonDisabled(props) {
  return (
    <>
      {/* Button */}
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PaperAirplaneIcon />
          </SvgIcon>
        }
        variant="contained"
        color="info"
        disabled={true}
      >
        Tranferer
      </Button>
    </>
  );
}
