import Chip from "@mui/material/Chip";

const KjosChip = (props) => {
   const {label, color, variant, size} = props
  return (
    <>
          <Chip label={label} color={(color==="primary" || color==="secondary" || color==="error" || color==="warning" || color==="info")? color : "success"} size={size} variant={variant} sx={{color: "white", backgroundColor: color, fontWeight: "bold"}} />
    </>
  );
};

export default KjosChip