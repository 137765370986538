import { Backdrop, Box, Button, Chip, Container, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import { ADMIN_PLAINTE_URL } from "config/RoutesUrl";
import KjosSttepper from "components/KjosStepper";
import useStatut from "hooks/useStatut";
import useHistorique from "hooks/useHistorique";
import getFileTypeFromUrl from "controller/getFileTypeFromUrl";
import { useRef } from "react";
import ClasseSansuiteButton from "pages/settings/ClasseSansuiteButton";
import CommentButton from "pages/settings/CommentButton";
import TransfererButton from "pages/settings/TransfererButton";
import FinButton from "pages/settings/FinButton";
import useSignalement from "hooks/useSignalement";
import useUser from "hooks/useUser";
import getDefaultImage from "controller/getDefaultImage";
import ClasseSansuiteButtonDisabled from "pages/settings/ClasseSansuiteButtonDisabled";
import TransfererButtonDisabled from "pages/settings/TransfererButtonDisabled";
import useRole from "hooks/useRole";
import { setAlert } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import KjosChip from "components/KjosChip";
import ArrowUpTrayIcon from "@heroicons/react/24/solid/ArrowUpTrayIcon";
import { CREATE_COLOR, FANCY_BLUE, FANCY_GREEN, FANCY_ORANGE } from "config/color";
import formatUserName from "controller/formatUserName";
import { ArrowLeft } from "@mui/icons-material";
import { setUpdate } from "store/AuthenticationReducer";
import useAlert from "hooks/useAlert";

const PlainteDetailLayout = () => {
  const { getOneData, getData, putData } = useHttp(); // get data from server
  const router = useLocation();
  const { oneDataResult, update } = useSelector((state) => state.authentication); // get result state
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [plainte, setPlainte] = useState([]); // open snackbar
  const [typeSignalement, setTypeSignalement] = useState([]);
  const [habiletes, setHabiletes] = useState([]);
  const { color, label } = useStatut();
  const { getHistorique, setHistorique } = useHistorique();
  // const [historique, setHistorique] = useState([]);
  const gridDetailsRef = useRef();
  const { updateSignalement, isSignalementOwner, endedSignalement } = useSignalement();
  const { getUser, getUserHabilitation } = useUser();
  const [isOwner, setIsOwner] = useState(true);
  const isSuperAdmin = useRole("admin", "superadmin");
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {createAlert, updateAlert, deleteAlert} = useAlert();

  useEffect(() => {
    // Marque plainte as open
    async function fetchData() {
      const pl = await getOneData(router?.state?.id, ADMIN_PLAINTE_URL);
      
      setPlainte(pl?.data);
      // Check if is owner
    }
    fetchData(); // get data when component is mounted
  }, [router?.state?.id, update]);

  useEffect(() => {
    if (gridDetailsRef.current) {
      const divHeight = gridDetailsRef.current.clientHeight;
      
    }
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
          color: "",
        })
      );
    };
  }, []);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem", backgroundColor: "#F6F7FA", borderRadius: "10px" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"} sx={{ backgroundColor: "white", p: 5, borderRadius: 2, boxShadow: "0px 0px 5px grey" }}>
        <Stack></Stack>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack direction={"row"} justifyContent={"flex-start"} spacing={1}>
              <Button
                startIcon={
                  <SvgIcon fontSize="small">
                    <ArrowLeft />
                  </SvgIcon>
                }
                variant="contained"
                color="success"
                onClick={() => navigate(-1)}
                sx={{ bgcolor: FANCY_GREEN, color: "white" }}
              >
                Retour
              </Button>

              <Button
                variant="contained"
                color="success"
                sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                onClick={() => {
                  putData({ status: "take_charge" }, ADMIN_PLAINTE_URL + "/" + router?.state?.id);
                  dispatch(setUpdate());
                  updateAlert();
                }}
              >
                Prendre en charge
              </Button>

              <Button
                variant="contained"
                color="success"
                sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
                onClick={() => {
                  putData({ status: "without_continuation" }, ADMIN_PLAINTE_URL + "/" + router?.state?.id);
                  dispatch(setUpdate());
                  updateAlert();
                }}
              >
                Classer sans suite
              </Button>
            </Stack>
          </Grid>
          <Grid item md={12} sx={{ p: 4 }} ref={gridDetailsRef}>
            <Stack
              spacing={2}
              sx={{
                p: 4,
                backgroundColor: "#F6F7FA",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px grey",
              }}
              direction={"column"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6">Détails</Typography>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Numéro de plainte</Typography>
                <Typography variant="body">{plainte?.code}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body">Agent en charge</Typography>
                <Typography variant="body">{plainte?.admin?.nom + " " + plainte?.admin?.prenom}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Situation familiale</Typography>
                <Typography variant="body">{plainte?.situation_familiale}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Localité</Typography>
                <Typography variant="body">{plainte?.commune}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Condition d'utilisation</Typography>
                <Typography variant="body">{plainte?.condition_utilisation}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Date des faits</Typography>
                <Typography variant="body">{plainte?.date_faits}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Plaignant </Typography>
                <Typography variant="body">
                  {plainte?.nom_naissance} {plainte?.prenom}
                </Typography>
              </Stack>


              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Statut du plaignant</Typography>
                <Typography variant="body">{plainte?.victime}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Etat civile</Typography>
                <Typography variant="body">{plainte?.etat_civile}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Genre</Typography>
                <Typography variant="body">{plainte?.genre}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Nationalité</Typography>
                <Typography variant="body">{plainte?.nationalite}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Lieu des faits</Typography>
                <Typography variant="body">{plainte?.lieu_faits}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Lieu de naissance</Typography>
                <Typography variant="body">{plainte?.lieu_naissance}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Nom de la voie</Typography>
                <Typography variant="body">{plainte?.nom_de_la_voie}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Numero de rue</Typography>
                <Typography variant="body">{plainte?.numero_rue}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Objet de la plainte</Typography>
                <Typography variant="body">{plainte?.objet_plainte}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Personne mise en cause</Typography>
                <Typography variant="body">{plainte?.personne_mise_cause}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Profession</Typography>
                <Typography variant="body">{plainte?.profession}</Typography>
              </Stack>

              <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                <Typography variant="body"> Status</Typography>
                <KjosChip label={label(plainte?.status)} color={color(plainte?.status)} size="small" variant="contained" />
              </Stack>
            </Stack>
          </Grid>

          <Grid item md={12} sx={{ p: 4 }}>
            <Stack
              spacing={4}
              sx={{
                p: 4,
                backgroundColor: "#F6F7FA",
                borderRadius: "10px",
                boxShadow: "0px 0px 5px grey",
              }}
              direction={"column"}
              justifyContent={"space-between"}
            >
              <Stack spacing={2} direction={"column"}>
                <Typography variant="body">Faits et circonstances</Typography>
                <Stack
                  sx={{
                    p: 4,
                    height: "10rem",
                    overflow: "auto",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="body">{plainte?.faits_et_circonstances}</Typography>
                </Stack>
              </Stack>

              <Stack spacing={2} direction={"column"}>
                <Typography variant="body">Description des faits</Typography>
                <Stack
                  sx={{
                    p: 4,
                    height: "10rem",
                    overflow: "auto",
                    backgroundColor: "white",
                    borderRadius: "10px",
                  }}
                >
                  <Typography variant="body">{plainte?.description_faits}</Typography>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default PlainteDetailLayout;
