import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "config/Theme";
import { Stack } from "@mui/system";
import { useState } from "react";

export default function KjosSelect4(props) {
  const [value, setValue] = useState(null);
  return (
    <>
      <Stack direction={"column"} spacing={2}>
        <label htmlFor={props.id}> {props.label} </label>
        <select
          style={props.style}
          // value={props?.defaultValue?.id}
          value={value ?? props?.defaultValue}
          disabled={props.disabled}
          id={props.id}
          name={props.name}
          required={props.required}
          type={props.type}
          onChange={(e) => props?.onChange ?? setValue(e.target.value)}
        >
          {/* <option selected value={props?.defaultValue?.id}> {props?.defaultValue?.label} </option> */}
          {props?.options?.map((option, index) => (
            <option key={index} value={option?.id}>
              {option?.label}
            </option>
          ))}
        </select>
      </Stack>
    </>
  );
}
