import { useState } from "react";
import useHttp from "hooks/useHttp";
import { CMS_BODY, CMS_FOOTER, CMS_GET, CMS_HEADER, USER_URL } from "config/RoutesUrl";
import formatPhoneNumber from "controller/formatPhoneNumber";
import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Unstable_Grid2 as Grid, Typography, Stack, Chip } from "@mui/material";
import useUser from "hooks/useUser";
import KjosInput2 from "components/KjosInput2";
import KjosSnacBar from "components/KjosSnackBar";
import KjosInputImage from "components/KjosInputImage";
import { avatar_anonyme } from "config/image";
import { useRef } from "react";
import { FANCY_GREEN, MAIN_BLUE, MAIN_GRAY, UPDATE_COLOR } from "config/color";
import getDefaultImage from "controller/getDefaultImage";
import { useEffect } from "react";
import { setAlert } from "store/SignalementReducer";
import { useDispatch, useSelector } from "react-redux";
import KjosAlert from "components/KjosAlert";
import KjosChip from "components/KjosChip";
import scrollToTop from "controller/scrollToTop";
import KjosAuthorized from "components/KjosAuthorized";
import useAlert from "hooks/useAlert";
import { setUpdate } from "store/AuthenticationReducer";
import KjosRichText2 from "components/KjosRichText2";
const CmsEdit = (props) => {
  const logoRef = useRef();
  const { updateAlert, createAlert, deleteAlert, errorAlert } = useAlert();
  const logoCleanedRef = useRef(false);
  const { putData, getData, postData } = useHttp(); // get data from server
  const { getUser, updateUser } = useUser();
  const [cms, setcms] = useState([]);
  const [postUser, setPostUser] = useState([]);
  const [welcomeText, setWelcomeText] = useState();
  const [siteSlogan, setSiteSlogan] = useState();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [politiqueContent, setPolitiqueContent] = useState();
  const [conditionContent, setConditionContent] = useState();
  const logoCleanedRefFacebook = useRef(false);
  const facebookLogoRef = useRef();
  const logoCleanedRefInstagram = useRef(false);
  const instagramLogoRef = useRef();
  const logoCleanedRefTwitter = useRef(false);
  const twitterLogoRef = useRef();
  const logoCleanedRefLinkedin = useRef(false);
  const linkedinLogoRef = useRef();
  const { alert } = useSelector((state) => state.signalement);

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
  const dispatch = useDispatch();
  const { update } = useSelector((state) => state.authentication); // get result state

  const handleHeader = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget); // get form data
    const formData2 = new FormData();
    formData2.append("site_slogan", siteSlogan);
    // Si on change de photo
    if (!logoCleanedRef.current && logoRef.current.files[0]) {
      formData2.append("logo", logoRef.current.files[0]);
      formData2.append("logo_changed", "changed");
    }

    // Si on supprime photo
    if (logoCleanedRef.current && !logoRef.current.files[0]) {
      formData2.append("logo", "cleaned");
      formData2.append("logo_changed", "changed");
    }

    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!logoCleanedRef.current && !logoRef.current.files[0]) {
      formData2.append("logo_changed", "unchanged");
    }

    const postUs = await postData(formData2, CMS_HEADER);
    
    if (postUs?.error) {
      errorAlert();
    } else {
      createAlert();
      dispatch(setUpdate());
    }
  };

  const handleBody = async (e) => {
    e.preventDefault();
    const formData2 = new FormData();
    formData2.append("welcome_text", welcomeText);

    const postUs = await postData(formData2, CMS_BODY);

    if (postUs?.error) {
      errorAlert();
    } else {
      createAlert();
      dispatch(setUpdate());
    }
  };

  const handleFooter = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget); // get form data
    const formData2 = new FormData();

    formData2.append("contact_url", formData.get("contact_url"));
    formData2.append("politique_content", politiqueContent);
    formData2.append("confidentialite_content", conditionContent);
    formData2.append("email_url", formData.get("email_url"));
    formData2.append("facebook_name", formData.get("facebook_name"));
    formData2.append("facebook_url", formData.get("facebook_url"));
    formData2.append("instagram_name", formData.get("instagram_name"));
    formData2.append("instagram_url", formData.get("instagram_url"));
    formData2.append("twitter_name", formData.get("twitter_name"));
    formData2.append("twitter_url", formData.get("twitter_url"));
    formData2.append("linkedin_name", formData.get("linkedin_name"));
    formData2.append("linkedin_url", formData.get("linkedin_url"));

    // Facebook
    // Si on change de photo

    if (!logoCleanedRefFacebook.current && facebookLogoRef.current.files[0]) {
      formData2.append("facebook_logo", facebookLogoRef.current.files[0]);
      formData2.append("facebook_logo_changed", "changed");
    }

    // Si on supprime photo
    if (logoCleanedRefFacebook.current && !facebookLogoRef.current.files[0]) {
      formData2.append("facebook_logo", "cleaned");
      formData2.append("facebook_logo_changed", "changed");
    }

    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!logoCleanedRefFacebook.current && !facebookLogoRef.current.files[0]) {
      formData2.append("facebook_logo_changed", "unchanged");
    }

    // Instagram
    // Si on change de photo

    if (!logoCleanedRefInstagram.current && instagramLogoRef.current.files[0]) {
      formData2.append("instagram_logo", instagramLogoRef.current.files[0]);
      formData2.append("instagram_logo_changed", "changed");
    }

    // Si on supprime photo
    if (logoCleanedRefInstagram.current && instagramLogoRef.current.files[0]) {
      formData2.append("instagram_logo", "cleaned");
      formData2.append("instagram_logo_changed", "changed");
    }
    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!logoCleanedRefInstagram.current && !instagramLogoRef.current.files[0]) {
      formData2.append("instagram_logo_changed", "unchanged");
    }

    // Twitter
    // Si on change de photo
    if (!logoCleanedRefTwitter.current && twitterLogoRef.current.files[0]) {
      formData2.append("twitter_logo", twitterLogoRef.current.files[0]);
      formData2.append("twitter_logo_changed", "changed");
    }
    // Si on supprime photo
    if (logoCleanedRefTwitter.current && twitterLogoRef.current.files[0]) {
      formData2.append("twitter_logo", "cleaned");
      formData2.append("twitter_logo_changed", "changed");
    }
    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!logoCleanedRefTwitter.current && !twitterLogoRef.current.files[0]) {
      formData2.append("twitter_logo_changed", "unchanged");
    }

    // Linkedin
    // Si on change de photo
    if (!logoCleanedRefLinkedin.current && linkedinLogoRef.current.files[0]) {
      formData2.append("linkedin_logo", linkedinLogoRef.current.files[0]);
      formData2.append("linkedin_logo_changed", "changed");
    }
    // Si on supprime photo
    if (logoCleanedRefLinkedin.current && linkedinLogoRef.current.files[0]) {
      formData2.append("linkedin_logo", "cleaned");
      formData2.append("linkedin_logo_changed", "changed");
    }
    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!logoCleanedRefLinkedin.current && !linkedinLogoRef.current.files[0]) {
      formData2.append("linkedin_logo_changed", "unchanged");
    }

    const postUs = await postData(formData2, CMS_FOOTER);

    if (postUs?.error) {
      errorAlert();
    } else {
      createAlert();
      dispatch(setUpdate());
    }
  };

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleRadioChange = (event) => {
    setGender(event.target.value);
  };

  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
      setSiteSlogan(data?.data?.header?.site_slogan ?? "");
      setConditionContent(data?.data?.footer?.confidentialite_content ?? "");
      setPolitiqueContent(data?.data?.footer?.politique_content ?? "");
      setWelcomeText(data?.data?.body?.welcome_text ?? "");
      
    });
  }, []);

  return (
    <KjosAuthorized permission="CMS_management">
      <KjosAlert label={alert.message} />
      <form onSubmit={handleHeader} id="header">
        <Grid container spacing={3} p={{ xs: 2, md: 3, lg: 8 }}>
          <Grid xs={12}>
            <Card sx={{ bgcolor: MAIN_GRAY }}>
              <CardHeader subheader="Modifier l'entête de l'application cliente" title={<div>Entête</div>} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
              <CardContent sx={{ p: 2 }}>
                <Box sx={{ m: -1.5, p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Logo</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <KjosInputImage photoRef={logoRef} source={getDefaultImage(cms?.header?.logo_url)} defaultImage={avatar_anonyme} photoCleanedRef={logoCleanedRef} />
                        <Typography mt={2} variant="body2"></Typography>
                        10Mo max (jpg, png)
                      </Box>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6"> Titre du site</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>

                      <KjosRichText2 onUpdate={(value) => setSiteSlogan(value)} defaultContent={siteSlogan} />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                  Enregistrer
                </Button>
              </CardActions>
            </Card>

            {postUser?.error && (
              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                {postUser?.message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </form>

      <form onSubmit={handleBody} id="body">
        <Grid container spacing={3} p={{ xs: 2, md: 3, lg: 8 }}>
          <Grid xs={12}>
            <Card sx={{ bgcolor: MAIN_GRAY }}>
              <CardHeader subheader="Modifier le corps de l'application cliente" title={<div>Corps du site</div>} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5, p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Message de bienvenue</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosRichText2 onUpdate={(value) => setWelcomeText(value)} defaultContent={welcomeText} />
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                  Enregistrer
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </form>

      <form onSubmit={handleFooter} id="footer">
        <Grid container spacing={3} p={{ xs: 2, md: 3, lg: 8 }}>
          <Grid xs={12}>
            <Card sx={{ bgcolor: MAIN_GRAY }}>
              <CardHeader subheader="Gestion du pied de page" title={<div>Pied de page</div>} sx={{ backgroundColor: FANCY_GREEN, color: "white" }} />
              <CardContent sx={{ pt: 0 }}>
                <Box sx={{ m: -1.5, p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">URL de contact</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="contact_url" name="contact_url" type="text" width={"100%"} defaultValue={cms?.footer?.contact_url} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Email de contact</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="email_url" name="email_url" type="text" width={"100%"} defaultValue={cms?.footer?.email_url} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Politique de confidentialité</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Stack sx={{ width: "100%", height: "10rem", overflow: "auto" }}>
                        <KjosRichText2 onUpdate={(value) => setPolitiqueContent(value)} defaultContent={politiqueContent} />
                      </Stack>
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Condition Générale d'utilisation</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Stack sx={{ width: "100%", height: "10rem", overflow: "auto" }}>
                        <KjosRichText2 onUpdate={(value) => setConditionContent(value)} defaultContent={conditionContent} />
                      </Stack>
                    </Grid>

                    {/* Facebook */}
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Titre facebook</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="facebook_name" name="facebook_name" type="text" width={"100%"} defaultValue={cms?.footer?.facebook_name} />
                    </Grid>
                    

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">URL facebook</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="facebook_url" name="facebook_url" type="text" width={"100%"} defaultValue={cms?.footer?.facebook_url} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Logo facebook</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <KjosInputImage name="facebook_logo" id="facebook_logo" photoRef={facebookLogoRef} source={getDefaultImage(cms?.footer?.facebook_logo)} defaultImage={avatar_anonyme} photoCleanedRef={logoCleanedRefFacebook} />
                        <Typography mt={2} variant="body2"></Typography>
                        10Mo max (jpg, png)
                      </Box>
                    </Grid>

                    {/* Instagram */}
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Titre Instagram</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="instagram_name" name="instagram_name" type="text" width={"100%"} defaultValue={cms?.footer?.instagram_name} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">URL instagram</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="instagram_url" name="instagram_url" type="text" width={"100%"} defaultValue={cms?.footer?.instagram_url} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Logo instagram</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <KjosInputImage photoRef={instagramLogoRef} source={getDefaultImage(cms?.footer?.instagram_logo)} defaultImage={avatar_anonyme} photoCleanedRef={logoCleanedRefInstagram} />
                        <Typography mt={2} variant="body2"></Typography>
                        10Mo max (jpg, png)
                      </Box>
                    </Grid>

                    {/* Twitter */}
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Titre twitter</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="twitter_name" name="twitter_name" type="text" width={"100%"} defaultValue={cms?.footer?.twitter_name} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">URL twitter</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="twitter_url" name="twitter_url" type="text" width={"100%"} defaultValue={cms?.footer?.twitter_url} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Logo twitter</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <KjosInputImage photoRef={twitterLogoRef} source={getDefaultImage(cms?.footer?.twitter_logo)} defaultImage={avatar_anonyme} photoCleanedRef={logoCleanedRefTwitter} />
                        <Typography mt={2} variant="body2"></Typography>
                        10Mo max (jpg, png)
                      </Box>
                    </Grid>

                    {/* Linkedin */}
                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Titre Linkedin</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="linkedin_name" name="linkedin_name" type="text" width={"100%"} defaultValue={cms?.footer?.linkedin_name} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">URL linkedin</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <KjosInput2 id="linkedin_url" name="linkedin_url" type="text" width={"100%"} defaultValue={cms?.footer?.linkedin_url} />
                    </Grid>

                    <Grid xs={12} md={6}>
                      <Typography variant="h6">Logo linkedin</Typography>
                    </Grid>
                    <Grid xs={12} md={6}>
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <KjosInputImage photoRef={linkedinLogoRef} source={getDefaultImage(cms?.footer?.linkedin_logo)} defaultImage={avatar_anonyme} photoCleanedRef={logoCleanedRefLinkedin} />
                        <Typography mt={2} variant="body2"></Typography>
                        10Mo max (jpg, png)
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <CardActions sx={{ justifyContent: "flex-end" }}>
                  <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                    Enregistrer
                  </Button>
                </CardActions>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </form>
    </KjosAuthorized>
  );
};
export default CmsEdit;
