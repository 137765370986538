import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from 'store/SignalementReducer';

export default function KjosAlert(props) {
  const [open, setOpen] = React.useState(true);
  const {alert} = useSelector(state=>state.signalement)
  const dispatch = useDispatch();

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={alert.open}>
        <Alert 
        severity={(alert.color==="success" || alert.color==="error" || alert.color==="warning" || alert.color==="info" || alert.color==="secondary" || alert.color==="primary"  ) ? alert.color : "info"}
        variant='filled'
          action={
            <IconButton
              aria-label="close"
              color= "inherit"
              size="small"
              onClick={() => {
                dispatch(setAlert({...alert, open: false}));
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2, backgroundColor: alert.color, color:"white", fontWeight:"bold" }}
        >
          {
            alert.message
          }
        </Alert>
      </Collapse>
    </Box>
  );
}