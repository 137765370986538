import KjosHeader from "components/KjosHeader";
import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { AccountProfileDetails } from "pages/administrateurs/account-profile-details";
import { SettingsPassword } from "pages/administrateurs/settings-password";
import KjosTitle from "components/KjosTitle";
import { AccountCircle } from "@mui/icons-material";
import { FANCY_PINK } from "config/color";

const Account = () => (
  <>
    <KjosHeader>
      Compte | ANSSI
    </KjosHeader>
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: 8,
      }}
    >
      <Container maxWidth="lg">
      <KjosTitle icon={<AccountCircle />} title="Mon Profile" subtitle="Paramètre du profile" iconBgColor={FANCY_PINK} />
        <br />
        <Stack spacing={3}>
          <div>
            <Grid container 
            spacing={3}>
              <Grid item 
              xs={12}>
                <AccountProfileDetails />
              </Grid>
              <Grid item 
              xs={12}>
                <SettingsPassword />
              </Grid>
            </Grid>
          </div>
        </Stack>
      </Container>
    </Box>
  </>
);


export default Account;
