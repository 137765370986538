import { createTheme} from '@mui/material/styles';

export const theme = createTheme({
   palette: {
      main_blue: {
         main: "#1C409C"
      },
      blue_sky: {
         main: "#F5F6FA"
      },
      blue_gray:{
         main: "#C1C4C8"
      },
      blue_green:{
         main: "#BEE7FE"
      },
      main_red:{
         main: "#D02424"
      },
      fancy_blue:{
         main: "#4099ff"
      },
      fancy_pink:{
         main: "#f05170"
      },
      fancy_green:{
         main: "#59d8b6"
      },
      fancy_orange:{
         main: "#f8b64d"
      }
   },
})
