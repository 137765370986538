import { Card, CardContent, CardHeader } from '@mui/material';
import { DELIVERY_COLOR, FANCY_GREEN, NEW_COLOR, OPEN_COLOR, PENDDING_COLOR, WITHOUT_CONTINUATION_COLOR } from 'config/color';
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label, Tooltip, Legend } from 'recharts';

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];

const COLORS = [NEW_COLOR, PENDDING_COLOR, OPEN_COLOR, WITHOUT_CONTINUATION_COLOR,DELIVERY_COLOR ];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={"middle"} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
let renderLabel = function(entry) {
   return entry.name;
}

export default function KjosPieChart(props) { 
   const { data, sx, title } = props
    return (
      <Card sx={{width:"100%"}}>
      <CardHeader title={title} sx={{backgroundColor: FANCY_GREEN, color: "white"}}/>
      <CardContent sx={sx}>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart width={300} height={300}>
        <Legend layout="horizontal" verticalAlign="top" align="top" />
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={150}
            fill="#8884d8"
            dataKey="value"
            label = {renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Label value="Your Label Text" position="insideStart" />
          <Tooltip />

        </PieChart>
      </ResponsiveContainer>
      </CardContent>
      </Card>
    );
}
