import { Box, Container, Stack } from "@mui/material";
import AdministrateursLayout from "pages/administrateurs/AdministrateursLayout";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import KjosTitle from "components/KjosTitle";
import { SupervisorAccount } from "@mui/icons-material";
import { FANCY_BLUE } from "config/color";

const Administrateurs = () => {
  return (
    <KjosAuthorized permission="read_admin">
      <KjosHeader>Administrateurs | ANSSI</KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack sx={{ px: 5 }}>
            <KjosTitle icon={<SupervisorAccount />} title="Administrateurs" subtitle="Liste des administrateurs" iconBgColor={FANCY_BLUE} />
          </Stack>
          <br />
          <Stack spacing={3}>
            <AdministrateursLayout />
          </Stack>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default Administrateurs;
