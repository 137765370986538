import { InputAdornment, MenuItem, TextField } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "config/Theme";

export default function KjosInput(props) {
  return (
    <ThemeProvider theme={theme}>
      {props?.select ? (
        <>
          <TextField
            select={props.select}
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            rows={props.rows}
            multiline={props.multiline}
            onChange={props.onChange}
            sx={{ width: props.width, height: props.height }}
            error={props.error}
            id={props.id}
            name={props.name}
            required={props.required}
            type={props.type}
            label={props.label}
            color={props.color}
            focused={props.focused}
            variant="outlined"
            inputProps={{
              style: { backgroundColor: props.backgroundColor },
              maxLength: props.maxLength,
              hidden: props.hidden,
            }}
            InputProps={{
              sx: {
                backgroundColor: props.backgroundColor,
              },
              startAdornment: <InputAdornment position="start">{props.icon}</InputAdornment>,
            }}
            helperText={props.helperText}
          >
            {props?.options?.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </>
      ) : (
        <>
          <TextField
            maxRows={props.maxRows}
            defaultValue={props.defaultValue}
            value={props.value}
            disabled={props.disabled}
            placeholder={props.placeholder}
            rows={props.rows}
            multiline={props.multiline}
            sx={{ width: props.width, height: props.height }}
            error={props.error}
            id={props.id}
            name={props.name}
            required={props.required}
            type={props.type}
            label={props.label}
            color={props.color}
            focused
            variant="outlined"
            onChange={props.onChange}
            inputProps={{
              style: { backgroundColor: props.backgroundColor },
              maxLength: props.maxLength,
              hidden: props.hidden,
            }}
            InputProps={{
              sx: {
                backgroundColor: props.backgroundColor,
              },
              startAdornment: <InputAdornment position="start">{props.icon}</InputAdornment>,
            }}
            helperText={props.helperText}
          />
        </>
      )}
    </ThemeProvider>
  );
}
