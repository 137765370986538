import * as React from "react";
import { Box, Tab, Unstable_Grid2 as Grid, Container, Stack } from "@mui/material";

import { TabContext, TabList, TabPanel } from "@mui/lab";
import SignalementLayoutClasseSansSuite from "pages/signalements/layout/SignalementLayoutClasseSansSuite";
import SignalementLayoutClasseSansSuiteAdmin from "pages/signalements/layout/SignalementLayoutClasseSansSuiteAdmin";
import SignalementLayoutEnCour from "pages/signalements/layout/SignalementLayoutEnCour";
import SignalementLayoutEnCourAdmin from "pages/signalements/layout/SignalementLayoutEnCourAdmin";
import SignalementLayoutAdmin from "pages/signalements/layout/SignalementLayoutAdmin";
import SignalementLayout from "pages/signalements/layout/SignalementLayout";
import SignalementLayoutTermine from "pages/signalements/layout/SignalementLayoutTermine";
import SignalementLayoutTermineAdmin from "pages/signalements/layout/SignalementLayoutTermineAdmin";
import useRole from "hooks/useRole";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import KjosTitle from "components/KjosTitle";
import { FANCY_BLUE } from "config/color";

const Signalement = () => {
  const [value, setValue] = React.useState("1");
  const isSuperAdmin = useRole("admin", "superadmin");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <KjosAuthorized permission="read_signalement">
      <KjosHeader>Signalement | ANSSI - Workflow</KjosHeader>
      <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
        <Container maxWidth={"xl"}>
          <Stack sx={{ px: 5 }}>
            <KjosTitle icon={<BellAlertIcon />} title="Signalements" subtitle="Gestion des signalements" iconBgColor={FANCY_BLUE} />
          </Stack>
          <br />
          {/* <Box sx={{ width: "100%", typography: "body1", px:"12px" }}> */}
          {isSuperAdmin ? (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="Traitement" scrollButtons variant="scrollable" allowScrollButtonsMobile>
                  <Tab label="Nouveaux" value="1" />
                  <Tab label="En cours de traitement" value="2" />
                  <Tab label="Classés sans suite" value="3" />
                  <Tab label="Traités" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <SignalementLayout />
              </TabPanel>
              <TabPanel value="2">
                {" "}
                <SignalementLayoutEnCour />{" "}
              </TabPanel>
              <TabPanel value="3">
                {" "}
                <SignalementLayoutClasseSansSuite />
              </TabPanel>
              <TabPanel value="4">
                {" "}
                <SignalementLayoutTermine />
              </TabPanel>
            </TabContext>
          ) : (
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} aria-label="Traitement" scrollButtons variant="scrollable" allowScrollButtonsMobile>
                  <Tab label="Nouveaux" value="1" />
                  <Tab label="En cours de traitement" value="2" />
                  <Tab label="Classés sans suite" value="3" />
                  <Tab label="Traités" value="4" />
                </TabList>
              </Box>
              <TabPanel value="1">
                <SignalementLayoutAdmin />
              </TabPanel>
              <TabPanel value="2">
                <SignalementLayoutEnCourAdmin />
              </TabPanel>
              <TabPanel value="3">
                <SignalementLayoutClasseSansSuiteAdmin />
              </TabPanel>
              <TabPanel value="4">
                <SignalementLayoutTermineAdmin />
              </TabPanel>
            </TabContext>
          )}

          {/* </Box> */}
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default Signalement;
