import { Box, IconButton, Stack } from "@mui/material";
import getDefaultImage from "controller/getDefaultImage";
import { PhotoCamera } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { useState } from "react";

export default function KjosInputImage(props) {
  const { defaultImage, photoRef, accept, source, photoCleanedRef, name, id } = props;
  const [photosrc, setPhotosrc] = useState(null);
  const [imageExists, setImageExists] = useState(true);
  const handleImageError = () => {
    setImageExists(false);
  };

  const handleOnChange = (e) => {
    photoCleanedRef.current = false;
    setPhotosrc(URL.createObjectURL(e.target.files[0]))
  };

  return (
    <Stack direction={"column"} spacing={2} justifyContent={"center"} alignItems={"center"}>
      {imageExists ? (
        <>
          <img
            src={photosrc ?? source}
            style={{ width: "10rem", height: "10rem", objectFit: "cover" }}
            onError={handleImageError}
          />
        </>
      ) : (
        <>
          <img
            src={defaultImage}
            style={{ width: "10rem", height: "10rem", objectFit: "cover" }}
          />
        </>
      )}

      <input
        type="file"
        name={name ?? "photo"}
        id={id ?? "photo"}
        ref={photoRef}
        hidden
        onChange={handleOnChange}
        accept={accept || "image/jpg, image/jpeg, image/png"}
      />

      <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
        <IconButton onClick={() => photoRef.current.click()}>
          <PhotoCamera />
        </IconButton>{" "}
        <IconButton
          onClick={() => {
            setPhotosrc(getDefaultImage(null));
            photoRef.current.value = "";
            photoCleanedRef.current = true;
          }}
        >
          <Delete />
        </IconButton>
      </Stack>
    </Stack>
  );
}
