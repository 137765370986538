import { Box, Button, Container, Grid, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import KjosTitle from "components/KjosTitle";
import { FANCY_BLUE, FANCY_GREEN, CREATE_COLOR } from "config/color";
import FlagIcon from "@mui/icons-material/Flag";
import { Link, useLocation } from "react-router-dom";
import { RAPPORT_LIST_PATH } from "config/PathUrl";
import { Group } from "@mui/icons-material";
import { CheckCircle, Error, Warning } from "@mui/icons-material";
import { People } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";
import KjosSelect5 from "components/KjosSelect5";
import { useEffect, useState } from "react";
import KjosListTransfert from "../../components/KjosListTransfert";
import useSignalement from "../../hooks/useSignalement";
import { Skeleton } from "@mui/joy";
import { Send } from "@mui/icons-material";
import useUser from "../../hooks/useUser";
import KjosNextPage2 from "../../components/KjosNextPage2";
import useHttp from "../../hooks/useHttp";
import { ADMIN_RAPPORT_URL } from "../../config/RoutesUrl";
import KjosAlert from "components/KjosAlert";
import { setAlert } from "store/SignalementReducer";
import { useDispatch, useSelector } from "react-redux";

const destinataires = [
  {
    id: 0,
    label: "Groupe actuel",
  },
  {
    id: 1,
    label: "Selon les utilisateurs",
  },
  {
    id: 2,
    label: "Selon les groupes",
  },
];

export default function RapportSend() {
  const [currentDestinataire, setCurrentDestinataire] = useState(0);
  const [fadeIn, setFadeIn] = useState(true);
  const { getCurrentGroupe, getAllGroup, getAllSignalement } = useSignalement();
  const { getAllAdmins } = useUser();
  const location = useLocation();
  const filter = location.state?.filter;
  const groupe_by_date_start = location.state?.groupe_by_date_start;
  const groupe_by_date_end = location.state?.groupe_by_date_end;
  const signalement_id = location.state?.signalement_id;
  const [isLoading, setIsLoading] = useState(true);
  const [currentGroupe, setCurrentGroupe] = useState([]);
  const [data, setData] = useState([]);
  const [value, setValue] = useState([]);
  const [sendToGroup, setSendToGroup] = useState("admins");
  const { postData, getData } = useHttp();
  const { page, rowsPerPage, alert } = useSelector((state) => state.signalement);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleDestinataireChange = async (e) => {
    setFadeIn(false); // Reset the fade-in animation
    setTimeout(() => {
      setFadeIn(true); // Trigger the fade-in animation
    }, 0);
    setCurrentDestinataire(e.target.value);
    
    
    if (e.target.value == 0) {
      setSendToGroup("admins");
      getCurrentGroupe(signalement_id).then((data) => {
        setCurrentGroupe(data?.data?.groupe);
        setData(data?.data?.users);
      });
    } else if (e.target.value == 1) {
      setSendToGroup("admins");
      getAllAdmins().then((data) => {
        setData(data);
      });
    } else if (e.target.value == 2) {
      setSendToGroup("groups");
      getAllGroup().then((data) => {
        setData(data);
      });
    }
  };

  // When we check in left list
  const handleValue = (e) => {
    setValue(e);
    
  };

  const sendReport = async (e) => {
    if (value.length > 0) {
      setLoading(true);
      const query = "?signalement_id=" + signalement_id + "&send_to_group=" + sendToGroup + "&recipient=" + value.join(",");

      const send = await getData(ADMIN_RAPPORT_URL + query);
      
      
      
      if (send?.error) {
        dispatch(
          setAlert({
            open: true,
            message: "Une erreur s'est produite. Si le problème persiste, veuillez contacter l'administrateur.",
            color: "error",
          })
        );
      } else {
        dispatch(
          setAlert({
            open: true,
            message: "Rapport envoyé",
            color: CREATE_COLOR,
          })
        );
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Simulating an API call or other asynchronous operation
    setTimeout(() => {
      getCurrentGroupe(signalement_id).then((data) => {
        setCurrentGroupe(data?.data?.groupe);
        setData(data?.data?.users);
      });
    }, 1000);
  }, [getCurrentGroupe]);

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, []);

  return (
    <KjosAuthorized permission="report_management">
      <KjosNextPage2>
        <KjosAlert label={alert.message} />

        <KjosHeader>rapport | ANSSI</KjosHeader>
        <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
          <Container maxWidth={"xl"}>
            <Stack sx={{ p: 5 }}>
              <KjosTitle icon={<ReportIcon />} title="Rapport" subtitle="Envoyer le rapport par mail" iconBgColor={FANCY_BLUE} />
            </Stack>
            <br />

            <Stack spacing={3} direction={"column"}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <KjosSelect5 id="destinataires" label="selectionner le(s) distinataire(s)" name="destinataires" width={"100%"} options={destinataires} onChange={handleDestinataireChange} />
                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={12}>
                  <Typography variant="h6" className={fadeIn ? "fadeIn" : ""} sx={{ textAlign: "center" }}>
                    {" "}
                    Rapport selont le {destinataires[currentDestinataire].label?.toLocaleLowerCase()}
                    {" : "}
                    {currentDestinataire === 0 && currentGroupe}
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {isLoading ? "Lof" : <KjosListTransfert data={data} handleValue={handleValue} />}
                </Grid>
              </Grid>
              <Stack direction={"row"} justifyContent={"center"} spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                {
                  loading && <Typography variant="body2" className={fadeIn ? "fadeIn" : ""} sx={{ textAlign: "center" }}>
                    Envoie...
                    </Typography>
                }
                <Button
                  className={fadeIn ? "fadeIn" : ""}
                  startIcon={
                    <SvgIcon fontSize="small">
                      <Send />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={value?.length > 0 ? false : true}
                  onClick={sendReport}
                  sx={{ bgcolor: FANCY_GREEN, color: "white", height: "40px" }}
                >
                  Envoyer
                </Button>
              </Stack>
            </Stack>
          </Container>
        </Box>
      </KjosNextPage2>
    </KjosAuthorized>
  );
}
