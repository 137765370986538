import KjosHeader from 'components/KjosHeader';
import ArrowLeftIcon from '@heroicons/react/24/solid/ArrowLeftIcon';
import { Box, Button, Container, SvgIcon, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Error = () => (
  <>
    <KjosHeader>
        404 | ANSSI - Gestion des signalements 
    </KjosHeader>
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        minHeight: '100%'
      }}
    >
      <Container maxWidth="md">
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box
            sx={{
              mb: 3,
              textAlign: 'center'
            }}
          >
            <img
              alt="Under development"
              src="/assets/errors/error-404.png"
              style={{
                display: 'inline-block',
                maxWidth: '100%',
                width: 400
              }}
            />
          </Box>
          <Typography
            align="center"
            sx={{ mb: 3 }}
            variant="h3"
          >
            404: La page que vous essayez d&apos; accéder n&apos;existe pas 
          </Typography>
          <Typography
            align="center"
            color="text.secondary"
            variant="body1"
          >
            Soit vous avez essayé un itinéraire ombragé, soit vous êtes venu ici par erreur.
             Quoi qu&apos;il en soit, essayez d&apos;utiliser la navigation
          </Typography>
          <Button
            component={Link}
            to="/"
            startIcon={(
              <SvgIcon fontSize="small">
                <ArrowLeftIcon />
              </SvgIcon>
            )}
            sx={{ mt: 3 }}
            variant="contained"
          >
            Revenir au tableau de bord
          </Button>
        </Box>
      </Container>
    </Box>
  </>
);

export default Error;
