import { useState } from "react";
import usePermission from "hooks/usePermission";
import UnAuthorize from "layouts/UnAuthorize";
import { useEffect } from "react";
import { Box } from "@mui/system";

export default function KjosAuthorized(props) {
  const { permission } = props;
  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(true);
  const { hasPermission } = usePermission();

  useEffect(() => {
    hasPermission(permission).then((resp) => {
      setAuthorized(resp?.authorized);
      setLoading(false);
    });
  }, [hasPermission, permission]);

  return (
    <>
      {authorized ? (
        <>{props.children}</>
      ) : (
        <>
          {loading ? (
            <Box display="flex" alignItems="center" justifyContent={"center"} sx={{height: "100vh"}}>
               <div>Loading...</div>
            </Box>
          ) : (
            <UnAuthorize />
          )}
        </>
      )}
    </>
  );
}
