import { createSlice } from "@reduxjs/toolkit";

const initialState = {
   pingRoute : {
      result: null,
      loading: false,
      error: false,
   }
}
const pingRouteSlice = createSlice({
   name: "pingroute",
   initialState,
   reducers: {
      setResult: (state, action) => {
         state.pingRoute.result = action.payload
      },
      setloading: (state, action) => {
         state.pingRoute.loading = action.payload
      },
      seterror: (state, action) => {
         state.pingRoute.error = action.payload
      }
   }
})
export const { setResult, setloading, seterror } = pingRouteSlice.actions
export default pingRouteSlice.reducer