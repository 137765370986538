import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import useRegister from "hooks/useRegister";
import { SIGNIN_PATH } from "config/PathUrl";
import KjosHeader from "components/KjosHeader";
import { Link } from "react-router-dom";
import Error404 from "components/Error404";
import { useEffect, useState } from "react";
import KjosAlert from "components/KjosAlert";
import { useSelector } from "react-redux";

const Register = () => {
  const { setPassword, checkPasswordLink } = useRegister();
  const { alert } = useSelector((state) => state.signalement);
  // Get query
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const token = urlParams.get("token");
  const email = urlParams.get("email");
  const [auth, setAuth] = useState({
    error: false,
    show: false,
    message: null,
  });
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      password: formData.get("password"),
      password_confirmation: formData.get("password_confirmation"),
      role: "admin",
      email: email,
    };

    setPassword(data);
  };

  useEffect(() => {
    checkPasswordLink({ role: "admin", token: token, email: email }).then((res) =>
      setAuth({
        error: res?.error,
        show: !res?.error,
        message: res?.message,
      })
    );
  }, []);
  return (
    <>
    {auth.error && <Error404 />}
      {auth.show  && (
        <>
          <KjosAlert label={alert.message} />

          <KjosHeader>Register | ANSSI Gestion des signalements</KjosHeader>
          <Box
            sx={{
              flex: "1 1 auto",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                maxWidth: 550,
                px: 3,
                py: "100px",
                width: "100%",
              }}
            >
              <div>
                <Stack spacing={1} sx={{ mb: 3 }}>
                  <Typography variant="h6">Définir un mot de passe</Typography>
                  <Typography color="text.secondary" variant="body2">
                    Avez vous deja un compte? &nbsp;
                    <Link to={SIGNIN_PATH}>Log in</Link>
                  </Typography>
                </Stack>
                <form onSubmit={handleFormSubmit}>
                  <Stack spacing={3}>
                    {/* <TextField fullWidth label="Name" name="nom" />

              <TextField fullWidth label="Prenom" name="prenom" />
              <TextField fullWidth label="Email Address" name="email" type="email" /> */}
                    <TextField fullWidth label="Mot de passe" name="password" type="password" />
                    <TextField fullWidth label="Confirmer Mot de passe" name="password_confirmation" type="password" />
                  </Stack>
                  <Button fullWidth size="large" sx={{ mt: 3 }} type="submit" variant="contained">
                    Enregistrer
                  </Button>
                </form>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Register;
