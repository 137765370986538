export default function formatPhoneNumber(event){
   let formattedValue = '';
   if(typeof event === 'string'){
      const value = event.replace(/\D/g, ''); // Remove non-digit characters
      
      // if (value.length === 8 || value.length === 9 || value.length === 10 || value.length === 11 || value.length === 12 || value.length === 13 || value.length === 14) {
      //   formattedValue = `(${value.slice(0, 3)}) ${value.slice(3)}`;
      // } else {
      //   formattedValue = value;
      // }
      formattedValue = value;
   }else if(typeof event === 'undefined'){
      
   } 
   else{
      const value = event.target.value.replace(/\D/g, ''); // Remove non-digit characters
   
      // if (value.length === 8 || value.length === 9 || value.length === 10 || value.length === 11 || value.length === 12 || value.length === 13 || value.length === 14) {
      //   formattedValue = `(${value.slice(0, 3)}) ${value.slice(3)}`;
      // } else {
      //   formattedValue = value;
      // }
      formattedValue = value
   }
 
   return  formattedValue;
}