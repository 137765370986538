import { useTheme } from '@mui/material/styles';
import { logo } from 'config/image';

export const Logo = () => {
  const theme = useTheme();
  const fillColor = theme.palette.primary.main;

  return (
   <img src={logo} width={60} height={60} alt="Logo" style={{objectFit: 'cover'}}/>
  );
};
