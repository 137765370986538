export default function getCommentTypeLabel(text) {
      if(text === "new"){
        return "Création";
      }
      if(text === "open"){
        return "Ouvert";    
      }
      if(text === "pendding"){
        return "Traitement en cour";
      }
      if(text === "without_continuation"){
        return "Sans suite";  
      }
      if(text === "delivery"){
        return "Livraison";
      }
      if(text === "comment"){
        return "Commentaire";
      }
      if(text === "take_charge"){
        return "Prise en charge";
      }
}