import { Box, Button, Chip, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import KjosList2 from "components/KjosList2";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import { ADMIN_INDEX_TRAITEMENT, ADMIN_SIGNALLEMENT_QUERY_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_TYPE_SIGNALEMENT_URL, ADMIN_PLAINTE_URL } from "config/RoutesUrl";
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import KjosSyncButton from "components/KjosSyncButton";
import { setAlert, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import { useNavigate } from "react-router-dom";
import { PLAINTES_DETAIL_PATH } from "config/PathUrl";
import { FANCY_BLUE, FANCY_GREEN } from "config/color";
import usePlainte from "hooks/usePlainte";

const PlainteLayout = () => {
  const dispatch = useDispatch();
  const { getData } = useHttp(); // get data from server
  const router = useNavigate();
  const { update } = useSelector((state) => state.authentication); // get result state
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [plaintes, setPlaintes] = useState([]); // open snackbar
  const { page, rowsPerPage, alert } = useSelector((state) => state.signalement);
  const {getPlainte} = usePlainte()
  /* Table Header */
  const header = {
    // date: "Date",
    code: "Code",
    created_at: "Date des création",
    user: "Utilisateurs",
    objet_plainte: "objet de la plaintes",
    personne_en_charge: "Agent en charge",
    status: "Status",
  };

  const handleSearch = async (e) => {
    // const req = await getData(ADMIN_PLAINTE_URL + "?code=" + e.target.value);
    const pl = await getPlainte(page + 1, rowsPerPage, "order=desc&order_by=created_at&code=" + e.target.value);

    
    if (!pl?.error) {
      setPlaintes(pl ?? []);
    }
  };

  const handledetail = () => {
    itemsSelected.length === 1 && router(PLAINTES_DETAIL_PATH, { state: { id: itemsSelected[0] } });
  };



  useEffect(() => {
    async function fetchData() {
      const pl = await getPlainte(page + 1, rowsPerPage, "order=desc&order_by=created_at");
   
      setPlaintes(pl);
      dispatch(setLoading(false)); // setLoading
    }
    fetchData(); // get data when component is mounted
    // const interval = setInterval(fetchData(), 6000)
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch, page, rowsPerPage, update]);

  useEffect(() => {}, [itemsSelected]);

  useEffect(() => {
    return () => {
      dispatch(setPage(0));
      dispatch(setRowPerpage(5));
    };
  }, [dispatch]);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
            {/* <Typography variant="h6">Signalements non traités</Typography> */}
            <div>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <EyeIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={itemsSelected.length == 1 ? false : true}
                  onClick={handledetail}
                  sx={{ bgcolor: FANCY_GREEN, color: "white" }}
                >
                  Détails
                </Button>

                <KjosSyncButton />
              </Stack>
            </div>
          </Stack>

          <Stack>
            <KjosList2 data={plaintes} header={header} isCheckBox={true} key={page} title="Nouveaux" onSearch={handleSearch} placeholder="Rechercher par le numéro de la plainte" />
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default PlainteLayout;

