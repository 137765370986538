import { Box, Button, Chip, Container, IconButton, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import AdjustmentsHorizontalIcon from "@heroicons/react/24/solid/AdjustmentsHorizontalIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import {  ADMIN_USER_URL, USER_URL } from "config/RoutesUrl";
import Dialog from "components/Dialog";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import  CustomersSearch  from "controller/customers-search";
import useRole from "hooks/useRole";
import { avatar_anonyme } from "config/image";
import useUser from "hooks/useUser";
import { setAlert, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import { useNavigate } from "react-router-dom";
import { USER_EDIT_URL } from "config/PathUrl";
import KjosAlert from "components/KjosAlert";
import { DELETE_COLOR, FANCY_GREEN, FANCY_ORANGE, FANCY_PINK, UPDATE_COLOR } from "config/color";

const UtilisateursLayout = () => {
  const dispatch = useDispatch();
  const router = useNavigate();
  const { fetchUser, searchUser } = useUser();
  const { page, rowsPerPage } = useSelector((state) => state.signalement);
  const { getData, postData, putData, deleteData, getOneData } = useHttp(); // get data from server
  const { postResult, getResult, deleteResult, editResult, user } = useSelector((state) => state.authentication); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [confirm, setConfirm] = useState(false); // open snackbar
  const [users, setUsers] = useState([]); // open snackbar
  const [disabled, setDisabled] = useState({
    edit: true,
    suspend: true,
    reactivate: true,
  });

  /* Table Header */
  const header = {
    avatar: " ",
    nom: "Nom",
    prenom: "Prenom",
    email: "Email",
    contact: "Téléphone",
    adresse: "Adresse",
    statut: "Statut",
  };

  /* Dialog */
  const submitCallback = async (e) => {
    if (motif === "add") {
      const data = {
        libelle: libelleInputValue,
        type: typeInputValue,
        // url: urlInputValue,
      };
      await postData(data, USER_URL); //add
    } else if (motif === "edit") {
      const data = {
        libelle: libelleInputValue,
        // url: urlInputValue,
        type: typeInputValue,
        id: itemsSelected[0],
      };
      await putData(data, ADMIN_USER_URL + "/" + itemsSelected[0]); //edit
    }
  };

  const [libelleInputValue, setLibelleInputValue] = useState("");
  const [typeInputValue, setTypeInputValue] = useState("");
  const [urlInputValue, setUrlInputValue] = useState("");
  const handleLibellehange = (e) => {
    setLibelleInputValue(e.target.value);
  };
  const handleTypeChange = (e) => {
    setTypeInputValue(e.target.value);
  };

  const handleUrlChange = (e) => {
    setUrlInputValue(e.target.value);
  };

  const body = (
    <Stack spacing={2} direction={"column"}>
      <KjosInput onChange={handleLibellehange} value={libelleInputValue} label="Libellé*"></KjosInput>
      <KjosInput onChange={handleTypeChange} value={typeInputValue} label="Type*"></KjosInput>
      {/* <KjosInput onChange={handleUrlChange} value={urlInputValue} label="URL"></KjosInput> */}
    </Stack>
  );
  /* End Dialog */

  /* Button control */
  // const handleAdd = () => {
  //   router("/administrateurs/create");
  // };

  const handleEdit = () => {
    router(USER_EDIT_URL, {
      state: { id: itemsSelected[0] },
    });
  };
  const handleDesactivateUser = async () => {
    // await deleteData(itemsSelected, USER_URL); //edit

    const u = await getOneData(itemsSelected, USER_URL);
    const formData = new FormData();
    formData.append("statut", disabled.suspend ? "actif" : "inactif");
    // formData.append("nom", u?.nom);
    // formData.append("prenom", u?.prenom);
    // formData.append("email", u?.email);
    // formData.append("contact", u?.contact);
    // formData.append("adresse", u?.adresse);
    // // formData.append("habilete", u?.habilete);
    // formData.append("date_naissance", u?.date_naissance);
    // formData.append("pays", u?.pays);
    // formData.append("ville", u?.ville);
    // formData.append("role", u?.role);
    formData.append("action", "desactivate");

    const des = await putData(formData, USER_URL + "/" + itemsSelected);
    
    setConfirm(false);


    dispatch(setAlert({ open: true, message: disabled.suspend ? "Compte activé avec sucès" :"Compte désactivé avec succès", color: disabled.suspend ? UPDATE_COLOR : DELETE_COLOR }));
    // && setOpenSnackBar(true); //if we delete an item we open snackbar
  };
  const handleOnclose = () => {
    setOpenSnackBar(false);
    dispatch(setDeleteResult(null));
  };
  /* En Button control */

  // Recherche
  const handleSearch = async (e) => {
    const search = await searchUser(USER_URL, "nom=" + e.target.value + "&email=" + e.target.value);
    if (!search?.error) {
      setUsers(search);
    }
  };

  useEffect(() => {
    fetchUser(USER_URL, page + 1, rowsPerPage).then((res) => {
      
      setUsers(res);
      dispatch(setLoading(false)); // setLoading
    });

    if (!postResult?.error) {
      setLibelleInputValue(""); //if we submit without error reset form field
      setTypeInputValue(""); //if we submit without error reset form field
      // setUrlInputValue(""); //if we submit without error reset form field
    }

    return () => {
      setOpenSnackBar(false);
    };
  }, [getData, postResult, deleteResult, editResult, dispatch, fetchUser, page, rowsPerPage]);

  useEffect(() => {
    if (itemsSelected.length === 1) {
      getOneData(itemsSelected, USER_URL).then((res) => {
        if (!res?.error && res?.data?.statut === "inactif") {
          setDisabled({
            reactivate: false,
            edit: false,
            suspend: true,
          });
        } else {
          setDisabled({
            reactivate: true,
            edit: false,
            suspend: false,
          });
        }
      });
    } else {
      setDisabled({
        reactivate: true,
        edit: true,
        suspend: true,
      });
    }
  }, [itemsSelected]);

  useEffect(() => {
    return () => {
      dispatch(setPage(0));
      dispatch(setRowPerpage(5));
    };
  }, [dispatch]);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            {/* <Typography variant="h6">Gestions des Utilisateurs</Typography> */}
            {useRole("admin", "superadmin") && (
              <div>
                {confirm ? (
                  <Stack direction={"row"} spacing={2}>
                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <TrashIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      color="success"
                      onClick={() => setConfirm(false)}
                    >
                      Annuler
                    </Button>

                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <TrashIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      color="error"
                      onClick={handleDesactivateUser}
                    >
                      Confirmez-vous?
                    </Button>
                  </Stack>
                ) : (
                  <Stack direction={"row"} spacing={2}>
                    {/* <Button
                    startIcon={
                      <SvgIcon fontSize="small">
                        <PlusIcon />
                      </SvgIcon>
                    }
                    variant="contained"
                    onClick={handleAdd}
                  >
                    Ajouter
                  </Button> */}

                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <PencilIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      color="success"
                      onClick={handleEdit}
                      disabled={disabled.edit}
                      sx={{ bgcolor: FANCY_PINK, color: "white" }}
                    >
                      Modifier
                    </Button>

                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <TrashIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      color="error"
                      onClick={() => setConfirm(true)}
                      disabled={disabled.suspend}
                      sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
                    >
                      Suspendre
                    </Button>

                    <Button
                      startIcon={
                        <SvgIcon fontSize="small">
                          <TrashIcon />
                        </SvgIcon>
                      }
                      variant="contained"
                      color="error"
                      onClick={() => setConfirm(true)}
                      disabled={disabled.reactivate}
                      sx={{ bgcolor: FANCY_GREEN, color: "white" }}
                    >
                      Réactiver
                    </Button>
                  </Stack>
                )}
              </div>
            )}
          </Stack> 

          {/* Recherche */}
          {/* <CustomersSearch placeholder="Saisir le nom ou email" onChange={handleSearch} /> */}

          <Stack>
            <KjosList2 data={users ?? []} header={header} isCheckBox={true} key={page} title="Utilisateurs enrégistrés" onSearch={handleSearch} placeholder="Saisir le nom ou email" />
          </Stack>
        </Stack>
      </Container>

      <Dialog submitCallback={submitCallback} body={body} title="Ajouter" button="Valider" />

      {/* When we delete an item we open snackbar */}
      {/* <KjosSnacBar open={openSnackBar} onClose={handleOnclose} message={(deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ?? deleteResult?.message} autoHideDuration={6000} severity={deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"} /> */}
    </Box>
  );
};

export default UtilisateursLayout;
