/* eslint-disable react/jsx-max-props-per-line */
import { Box, Button, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult, setOpen } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import { ADMIN_TYPE_SIGNALEMENT_URL, CLASSE_SIGNALEMENT_CREATE_URL, CLASSE_SIGNALEMENT_GET_URL, USER_CLASSE_SIGNALEMENT_GET_URL } from "config/RoutesUrl";
import Dialog from "components/Dialog";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { setAlert, setCount, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import { CREATE_COLOR, DELETE_COLOR, FANCY_BLUE, FANCY_ORANGE, FANCY_PINK, UPDATE_COLOR } from "config/color";
import KjosTextArea from "components/KjosTextArea";

const TypeSignalement = () => {
  const dispatch = useDispatch();
  const { getData, postData, putData, deleteData } = useHttp(); // get data from server
  const { postResult, getResult, deleteResult, editResult } = useSelector((state) => state.authentication); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [classeSignalement, setClassSignalement] = useState([]);
  const [typeSignalement, setTypeSignalement] = useState([]);
  const { page, rowsPerPage } = useSelector((state) => state.signalement);
  const [libelleInputValue, setLibelleInputValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [classeInputValue, setClasseInputValue] = useState("");
  /* Table Header */
  const header = {
    libelle: "Libelle",
    classe_signalement: "Classe de signalement",
    description: "Description",
  };

  const handleSearch = async (e) => {
    const req = await querySignalement(e.target.value);
    setSignalements(req)
  };

  /* Dialog */
  const submitCallback = async (e) => {
    if (classeInputValue === "" || libelleInputValue === "" || classeInputValue === null || libelleInputValue === null || classeInputValue === undefined || libelleInputValue === undefined || descriptionValue === "" || descriptionValue === null || descriptionValue === undefined) {
      dispatch(setAlert({ open: true, message: "Veuillez remplir tous les champs", color: "error" }));
      return;
    }
    if (motif === "add") {
      const data = {
        libelle: libelleInputValue,
        classe_signalement_id: classeInputValue,
        description: descriptionValue
      };

      await postData(data, ADMIN_TYPE_SIGNALEMENT_URL); //add
      dispatch(setAlert({ open: true, message: "Type de signalement ajouté avec succès", color: CREATE_COLOR }));
      setOpenSnackBar(false);
      dispatch(setOpen(false));
    } else if (motif === "edit") {
      const data = {
        libelle: libelleInputValue,
        classe_signalement_id: classeInputValue,
        description: descriptionValue
      };

      await putData(data, ADMIN_TYPE_SIGNALEMENT_URL + "/" + itemsSelected[0]); //edit
      dispatch(setAlert({ open: true, message: "Type de signalement mis à jour avec succès", color: UPDATE_COLOR }));
      setOpenSnackBar(false);
      dispatch(setOpen(false));
    }

    setLibelleInputValue("");
    setClasseInputValue("");
    setDescriptionValue("");
  };


  const handleOnLibelleChange = (e) => {
    setLibelleInputValue(e.target.value);
  };
  const handleOnDescriptionChange = (e) => {
    setDescriptionValue(e.target.value);
  };
  const handleOnClasseChange = (e) => {
    setClasseInputValue(e.target.value);
  };
  const body = (
    <Stack direction={"column"} spacing={2}>
      <KjosInput onChange={handleOnLibelleChange} value={libelleInputValue} width="100%" focused={true} label="Libellé"></KjosInput>
      <KjosInput select={true} options={classeSignalement} onChange={handleOnClasseChange} value={classeInputValue} width="100%" focused={true} label="Classe de signalement"></KjosInput>
      <KjosTextArea onChange={handleOnDescriptionChange} value={descriptionValue} width="100%" label="Description"></KjosTextArea>
    </Stack>
  );
  /* End Dialog */

  /* Button control */
  const handleAdd = () => {
    setMotif("add");
    dispatch(setOpen(true));
  };

  const handleEdit = () => {
    setMotif("edit");
    if (itemsSelected.length == 1) {
      getResult?.data?.forEach((item) => {
        if(item.id == itemsSelected[0]){
          setLibelleInputValue(item?.libelle)
          setDescriptionValue(item?.description)
          setClasseInputValue(item?.classe_signalement?.id)
        }

        // item.id == itemsSelected[0] ? setLibelleInputValue(item.libelle) : "";
        return false;
      });
      dispatch(setOpen(true));
    }
  };
  const handleDelete = async () => {
    if (itemsSelected === undefined || itemsSelected === "") {
      dispatch(setAlert({ open: true, message: "Une erreur inconu s'est produite", color: "error" }));
      return;
    }

    await deleteData(itemsSelected, ADMIN_TYPE_SIGNALEMENT_URL); //edit
    dispatch(setAlert({ open: true, message: "Type de signalement supprimé avec succès", color: DELETE_COLOR }));
  };
  const handleOnclose = () => {
    setOpenSnackBar(false);
    dispatch(setDeleteResult(null));
  };
  /* En Button control */

  useEffect(() => {
    async function fetchData() {
      const claSign = (await getData(USER_CLASSE_SIGNALEMENT_GET_URL))?.data?.map((item) => ({
        id: item.id,
        value: item?.id,
        label: item?.libelle,
        habiletes: item?.habiletes,
      }));
      const type = await getData(ADMIN_TYPE_SIGNALEMENT_URL + `?page=${page + 1}&per_page=${rowsPerPage}`);
      const typeSign = type?.data?.map((item) => ({
        libelle: item?.libelle,
        classe_signalement: item?.classe_signalement?.libelle,
        id: item?.id,
        description: item?.description,
      }));
      setTypeSignalement(typeSign);
      setClassSignalement(claSign);
      
      dispatch(setCount(type.total));
      dispatch(setLoading(false)); // setLoading
    }
    fetchData(); // get data when component is mounted

    !postResult?.error && setLibelleInputValue(""); //if we submit without error reset form field
    // deleteResult && setOpenSnackBar(true) //if we delete an item we open snackbar

    return () => {
      setOpenSnackBar(false);
    };
  }, [getData, postResult, deleteResult, editResult, dispatch, page, rowsPerPage]);

  useEffect(() => {}, [itemsSelected]);

  useEffect(() => {
    return () => {
      dispatch(setPage(0));
      dispatch(setRowPerpage(5));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6">Gestion des types de signalement</Typography>
            <div>
              <Stack direction={"row"} spacing={2}>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={handleAdd}
                  sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                >
                  Ajouter
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PencilIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="success"
                  onClick={handleEdit}
                  disabled={itemsSelected.length == 1 ? false : true}
                  sx={{ bgcolor: FANCY_PINK, color: "white" }}
                  >
                  Modifier
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <TrashIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  disabled={itemsSelected.length >= 1 ? false : true}
                  sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
                  >
                  Supprimer
                </Button>
              </Stack>
            </div>
          </Stack>

          <Stack>
            <KjosList2 data={typeSignalement} header={header} isCheckBox={true} key={page} title="Type de signalement" />
          </Stack>
        </Stack>
      </Container> 

      <Dialog submitCallback={submitCallback} body={body} title="Ajouter" button="Valider" />

      {/* When we delete an item we open snackbar */}
      {/* <KjosSnacBar open={openSnackBar} onClose={handleOnclose} message={(deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ?? deleteResult?.message} autoHideDuration={6000} severity={deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"} /> */}
    </Box>
  );
};

export default TypeSignalement;
