import { avatar_anonyme } from "config/image";
import { BASE_URL } from "config/RoutesUrl";

export default function getDefaultImage(image) {
  if (
    image === null ||
    image === "" ||
    image === undefined ||
    image === "null" ||
    image === "undefined" ||
    image === "" ||
    image === " "
  ) {
    return avatar_anonyme;
  }

  if (image.startsWith("/")) {
    image = image.substring(1);
  }

  return BASE_URL + "/" + image;
}