import useUser from "hooks/useUser";
import useIsAuthenticate from "hooks/useIsAuthenticate";
import KjosBackLog from "./KjosBackdrop";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/AuthenticationReducer";

function IsAuthUser(props) {
  const {isAuthenticated, isAuthUser} = useIsAuthenticate(); //will check if user is authenticated
  const {updateUser, getUser} = useUser()

    //get user first time 
    useEffect(() => {getUser("admin")?.user},[getUser])

  useEffect(() => {
    isAuthUser();
  },[isAuthUser, isAuthenticated])
  return (
      <>
        {/* Backlog to load spinner when page is loading  */}
        <KjosBackLog open={!isAuthenticated} />
        {isAuthenticated && props.children}
      </>
  );
}

export default IsAuthUser;
