import KjosHeader from 'components/KjosHeader';
import { Box, Container, Stack } from '@mui/material';
import PlainteLayout from 'pages/plaintes/layout/PlainteLayout';
import KjosAuthorized from "components/KjosAuthorized";
import { FANCY_BLUE } from 'config/color';
import KjosTitle from "components/KjosTitle";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ListeDesPlaintes = () => {

  return (
    <KjosAuthorized permission="complaints_management">
      <KjosHeader>
          Liste des plaintes | ANSSI
      </KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
        <KjosTitle icon={<ErrorOutlineIcon />} title="Plaintes" subtitle="Liste des plaintes" iconBgColor={FANCY_BLUE} />
          <br />          
          <Stack spacing={3}>
           <PlainteLayout />
          </Stack>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default ListeDesPlaintes;
