import { Stack, SvgIcon, Typography } from "@mui/material";

export default function KjosTitle(props) {
   const {icon, title, subtitle, iconBgColor, size} = props
   return (
     <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"flex-start"}>
      {/* Icon */}
      <SvgIcon fontSize={size ?? "large"} sx={{boxShadow: "0px 0px 5px grey", color:"white", bgcolor:iconBgColor, p:1, borderRadius: 1}}>
         {icon}
      </SvgIcon>
      {/* Text */}
      <Stack direction={"column"}>
        <Typography variant="h6"> {title} </Typography>
        <Typography variant="body2">
          {" "}
          {subtitle}
        </Typography>
      </Stack>
     </Stack>
   );
}