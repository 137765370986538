import { useCallback } from "react";
import useHttp from "hooks/useHttp";
import useUser from "./useUser";
import { ADMIN_INDEX_TRAITEMENT, ADMIN_HABILETE_URL, ADMIN_TYPE_SIGNALEMENT_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_HISTORIQUE_URL, ADMIN_ADMIN_ACTION_URL, ADMIN_SIGNALLEMENT_QUERY_URL, ADMIN_PLAINTE_URL } from "config/RoutesUrl";
import getArrayItemsById from "controller/getArrayItemsById";
import useRole from "./useRole";
import { Chip } from "@mui/material";
import useStatut from "./useStatut";
import { useDispatch } from "react-redux";
import { setCount } from "store/SignalementReducer";
import KjosChip from "components/KjosChip";
import { FANCY_ORANGE } from "config/color";
import formatUserName from "controller/formatUserName";
import dateToFrenchFormat from "controller/dateToFrenchFormat";
import usePermission from "./usePermission";

const usePlainte = () => {
  const { getData, postData, putData, deleteData, getOneData, deleteData2 } = useHttp(); // get data from server
  const { getUser } = useUser();
  const isSuperAdmin = useRole("admin", "superadmin");
  const { color, label } = useStatut();
  const dispatch = useDispatch();
  const { hasPermission } = usePermission();

  const createPlainte = useCallback(
    async (data, type_signalement_url = ADMIN_TYPE_SIGNALEMENT_URL, index_traitement_url = ADMIN_INDEX_TRAITEMENT) => {
      const creatSign = await postData(data, ADMIN_SIGNALLEMENT_URL);
      if (creatSign?.error) {
        return {
          error: true,
          message: "Erreur de création",
        };
      } else {
        // on recupère habilete_position du signalement, si habilete_position === longueur classe signalement on ne transfert plus
        const habilete_position = creatSign?.data?.habilete_position;
        const habiletes = creatSign?.data?.classe?.habiletes;
        // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
        if (Array.isArray(habiletes)) {
          if (habilete_position < habiletes.length - 1) {
            // const current_habiletes = habiletes[habilete_position + 1]; //code original
            const current_habiletes = habiletes[habilete_position]; //code de test

            if (Array.isArray(current_habiletes)) {
              current_habiletes.forEach(async (item) => {
                const data = {
                  habilete_id: item,
                  signalement_id: creatSign?.data?.id,
                };
                const data2 = {
                  habilete_id: item,
                  signalement_id: creatSign?.data?.id,
                  action: "create",
                };
                await postData(data, index_traitement_url);

                // On logs
                await postData(data2, ADMIN_ADMIN_ACTION_URL);
              });
            } else {
              const data = {
                habilete_id: current_habiletes,
                signalement_id: creatSign?.data?.id,
              };
              const data2 = {
                habilete_id: current_habiletes,
                signalement_id: creatSign?.data?.id,
                action: "create",
              };
              await postData(data, index_traitement_url);
              await postData(data2, ADMIN_ADMIN_ACTION_URL);
            }
          }
        }

        return {
          error: false,
          message: "Signalement enregistré avec succès",
          data: creatSign?.data,
        };
      }
    },
    [postData]
  );

  const updatePlainte = useCallback(
    async (id_signalement, data, signalement_url = ADMIN_SIGNALLEMENT_URL, historique_url = ADMIN_HISTORIQUE_URL) => {
      // On recupère la liste des commentaires de la table historique. On vérifie si le signalement est sujet de commentaire avant de le classer sans suite

      if (data?.statut === "without_continuation") {
        const historique = await getOneData(id_signalement, historique_url);
        if (!historique?.error) {
          if (historique?.libelle === null) {
            return {
              error: true,
              message: "Vous devez effectuer un commentaire avant de poursuivre",
            };
          } else {
            // on retourn le résultat
            const classeSignalement = await putData(data, signalement_url + "/" + id_signalement);
            return classeSignalement;
          }
        } else {
          return { error: true, message: "Une erreur c'est produite" };
        }
      } else {
        // on retourn le résultat
        const classeSignalement = await putData(data, signalement_url + "/" + id_signalement);
        return classeSignalement;
      }
    },
    [getOneData, putData]
  );

  const getPlainte = useCallback(
    async (page = 1, per_page = 5, query = null) => {
      let req;
      let pl;
      let ppage = page === null ? "?null=" + page : "?page=" + page;
      let ppper_page = per_page === null ? "&null=" + per_page : "&per_page=" + per_page ;

      const authorized = (await hasPermission("complaints_management"))?.authorized;
      if (authorized) {
        if (isSuperAdmin) { 
          pl = query ? await getData(ADMIN_PLAINTE_URL + ppage + ppper_page + "&" + query) : await getData(ADMIN_PLAINTE_URL + "?page=" + page + "&per_page=" + per_page);
        } else {
          pl = query ? await getData(ADMIN_PLAINTE_URL + page + "&per_page=" + per_page + "&admin_id=" + getUser("admin")?.user?.id + "&" + query) : await getData(ADMIN_PLAINTE_URL + "?page=" + page + "&per_page=" + per_page + "&admin_id=" + getUser("admin")?.user?.id);
        }
        dispatch(setCount(pl?.count));

        req = pl?.data?.map((item) => {
          return {
            id: item?.id,
            code: item?.code,
            personne_en_charge: (item?.admin?.nom ?? "") + " " + (item?.admin?.prenom ?? ""),
            commune: item?.commune?.libelle ?? "",
            created_at: dateToFrenchFormat(item?.created_at),
            date_faits: dateToFrenchFormat(item?.date_faits),
            description_faits: item?.description_faits,
            date_naissance: dateToFrenchFormat(item?.date),
            etat_civile: item?.etat_civile,
            genre: item?.genre,
            lieu_faits: item?.lieu_faits,
            lieu_naissance: item?.lieu_naissance,
            nationalite: item?.nationalite,
            nom_de_la_voie: item?.nom_de_la_voie,
            numero_rue: item?.numero_rue,
            objet_plainte: item?.objet_plainte,
            pays: item?.pays,
            personne_mise_cause: item?.personne_mise_cause,
            prenom: item?.prenom,
            profession: item?.profession,
            signalments: item?.signalements?.code ?? "",
            situation_familiale: item?.situation_familiale,
            user: (item?.user?.nom ?? "") + " " + (item?.user?.prenom ?? ""),
            victime: item?.victime,
            status: <KjosChip label={label(item?.status)} color={color(item?.status)} size="small" variant="contained" />,
          };
        });

      } else {
        return {
          error: true,
          message: "Vous n'avez pas les autorisations pour effectuer cette operation",
        };
      }

      // on retourn le résultat

      return req;
    },
    [color, dispatch, getData, getUser, isSuperAdmin, label]
  );

  const querySignalement = useCallback(
    async (code) => {
      // on recupère l'utilisateur
      const req = await getData(ADMIN_SIGNALLEMENT_QUERY_URL + "?code=" + code);
      let data = [];
      if (!req?.error) {
        const sign = req?.data?.data && req?.data?.data[0];
        if (sign === undefined || (sign === null && sign === "")) {
          return [];
        } else if (Object.keys(sign).length === 0) {
          return [];
        } else {
          data = {
            code: sign?.code,
            date: sign?.date,
            type: sign?.type_signalement?.libelle,
            id: sign?.id,
            user: sign?.user ? sign?.user?.nom + " " + sign?.user?.prenom : "Anonyme",
            localites: sign?.localites,
            workflow: <KjosChip label={label(sign?.status ?? "")} color={color(sign?.status ?? "")} size="small" variant="contained" />,
          };
        }
      }

      // on retourn le résultat

      return [data];
    },
    [color, dispatch, getData, getUser, isSuperAdmin, label]
  );

  const transfererSignalement = useCallback(
    async (signalementId, index_traitement_url = ADMIN_INDEX_TRAITEMENT) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      var createIndex;
      // On recupere index de traitement
      if (isSuperAdmin) {
        const habilete_position = signalement?.habilete_position;
        const habiletes = signalement?.classe?.habiletes;

        if (Array.isArray(habiletes)) {
          if (habilete_position < habiletes.length - 1) {
            const current_habiletes = habiletes[habilete_position + 1];
            const old_habiletes = habiletes[habilete_position];

            // const current_habiletes = habiletes[habilete_position]; //code de test
            if (Array.isArray(current_habiletes)) {
              await Promise.all(
                current_habiletes.map(async (item) => {
                  const data = {
                    habilete_id: item,
                    signalement_id: signalementId,
                  };
                  createIndex = await postData(data, index_traitement_url);

                  const data2 = {
                    habilete_id: item,
                    signalement_id: signalementId,
                    action: "create",
                  };
                  await postData(data2, ADMIN_ADMIN_ACTION_URL);
                })
              );
            } else {
              const data = {
                habilete_id: current_habiletes,
                signalement_id: signalementId,
              };
              createIndex = await postData(data, index_traitement_url);
            }

            // Mise à jour statut
            await updatePlainte(signalement?.id, {
              statut: "pendding",
              habilete_position: habilete_position + 1,
            });

            // On supprime les anciens index
            if (!createIndex?.error) {
              await Promise.all(
                old_habiletes?.map(async (item) => {
                  const del = await deleteData2(index_traitement_url + "/" + signalementId + "?habilete_id=" + item);
                })
              );
            }

            return {
              error: false,
              message: "Le signalement a été transféré avec succès",
            };
          } else {
            return {
              error: true,
              message: "Vous avez atteint la fin du traitement",
            };
          }
        }
      } else {
        const habilete_id = getUser("admin")?.user.habilete?.id ?? getUser("admin")?.user.habilete_id;

        const index_traitement = await getData(index_traitement_url + "/" + signalementId + "?habilete_id=" + habilete_id);

        const habilete_position = signalement?.habilete_position;
        const habiletes = signalement?.classe?.habiletes;

        // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
        if (Array.isArray(habiletes)) {
          if (habilete_position < habiletes.length - 1) {
            const current_habiletes = habiletes[habilete_position + 1];
            const old_habiletes = habiletes[habilete_position];

            // const current_habiletes = habiletes[habilete_position]; //code de test
            if (Array.isArray(current_habiletes)) {
              await Promise.all(
                current_habiletes.map(async (item) => {
                  const data = {
                    habilete_id: item,
                    signalement_id: signalementId,
                  };
                  const data2 = {
                    habilete_id: item,
                    signalement_id: signalementId,
                    action: "create",
                  };
                  createIndex = await postData(data, index_traitement_url);
                  await postData(data2, ADMIN_ADMIN_ACTION_URL);
                })
              );
            } else {
              const data = {
                habilete_id: current_habiletes,
                signalement_id: signalementId,
              };
              const data2 = {
                habilete_id: current_habiletes,
                signalement_id: signalementId,
                action: "create",
              };
              createIndex = await postData(data, index_traitement_url);
            }

            // Mise à jour statut
            await updatePlainte(signalement?.id, {
              statut: "pendding",
              habilete_position: habilete_position + 1,
            });

            // On supprime les anciens index
            if (!createIndex?.error) {
              await Promise.all(
                old_habiletes?.map(async (item) => {
                  const del = await deleteData2(index_traitement_url + "/" + signalementId + "?habilete_id=" + item);
                })
              );
            }

            return {
              error: false,
              message: "Le signalement a été transféré avec succès",
            };
          } else {
            return {
              error: true,
              message: "Vous avez atteint la fin du traitement",
            };
          }
        }
      }

    },
    [deleteData2, getData, getOneData, getUser, isSuperAdmin, postData, updatePlainte]
  );

  const nexGroup = useCallback(
    async (signalementId, habiletes_url = ADMIN_HABILETE_URL) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      // on recupère habilete_position du signalement, si habilete_position === longueur classe signalement on ne transfert plus
      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;
      const classes = signalement?.classe?.libelle;

      // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
      if (Array.isArray(habiletes)) {
        if (habilete_position < habiletes.length - 1) {
          const current_habiletes = habiletes[habilete_position + 1]; //code original
          // const current_habiletes = habiletes[habilete_position]; //code de test
          var habiletesLAbel = [];
          if (Array.isArray(current_habiletes)) {
            // On recupere les habiletes
            await Promise.all(
              current_habiletes.map(async (item) => {
                const hab = await getOneData(item, habiletes_url);
                habiletesLAbel.push(hab?.data?.libelle);
              })
            );
          } else {
            const hab = await getOneData(current_habiletes, habiletes_url);
            habiletesLAbel.push(hab);
          }
          return {
            error: false,
            data: { classe: classes, habiletes: habiletesLAbel },
          };
        } else {
          return {
            error: true,
            message: "Vous avez atteint la fin du traitement",
          };
        }
      } else {
        return {
          error: false,
          message: "Aucune erreur",
        };
      }
    },
    [getOneData]
  );

  const isSignalementOwner = useCallback(
    async (signalement_id) => {
      if (isSuperAdmin) return true;

      // Check if is owner
      const habilete_id = getUser("admin")?.user?.habilete?.id ?? getUser("admin")?.user?.habilete_id;

      let owner = await getData(ADMIN_INDEX_TRAITEMENT + "/" + signalement_id + "?habilete_id=" + habilete_id);

      if (Array.isArray(owner?.data) && owner?.data?.length === 0) {
        return false;
      } else {
        return true;
      }
    },
    [getData, getUser, isSuperAdmin]
  );

  const endedSignalement = useCallback(
    async (signalementId) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;
      if (Array.isArray(habiletes)) {
        if (habilete_position === habiletes.length - 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    [getOneData]
  );

  const markAsDelivery = useCallback(
    async (signalementId, index_traitement_url = ADMIN_INDEX_TRAITEMENT) => {
      const signalement = (await getOneData(signalementId, ADMIN_SIGNALLEMENT_URL))?.data;
      var createIndex;
      // On recupere index de traitement
      const habilete_id = getUser("admin")?.user.habilete?.id ?? getUser("admin")?.user.habilete_id;

      const index_traitement = await getData(index_traitement_url + "/" + signalementId + "?habilete_id=" + habilete_id);

      const habilete_position = signalement?.habilete_position;
      const habiletes = signalement?.classe?.habiletes;

      // recuperer la classe [[1,2,3], [1,2], [5,7,8]] du traitement suivant
      if (Array.isArray(habiletes)) {
        if (habilete_position === habiletes.length - 1) {
          const old_habiletes = habiletes[habilete_position];

          const update = await updatePlainte(signalementId, { statut: "delivery" });

          // On supprime les anciens index
          // if (!update?.error) {
          //  await Promise.all(
          //     old_habiletes?.map(async (item) => {
          //       const del = await deleteData2(
          //         index_traitement_url + "/" + signalementId + "?habilete_id=" + item
          //       );
          //     })
          //   );
          // }

          return {
            error: false,
            message: "succès",
          };
        } else {
          return {
            error: true,
            message: "Erreur inatendue",
          };
        }
      }
    },
    [deleteData2, getData, getOneData, getUser, updatePlainte]
  );

  return {
    createPlainte,
    updatePlainte,
    getPlainte,
    transfererSignalement,
    nexGroup,
    isSignalementOwner,
    endedSignalement,
    markAsDelivery,
    querySignalement,
  };
};

export default usePlainte;
