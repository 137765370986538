import ArrowRightIcon from '@heroicons/react/24/solid/ArrowRightIcon';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  Divider,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  ThemeProvider
} from '@mui/material';
import KjosChip from 'components/KjosChip';
import { Scrollbar } from 'components/scrollbar';
import { SeverityPill } from 'components/severity-pill';
import { theme } from 'config/Theme';
import { FANCY_BLUE, FANCY_GREEN } from 'config/color';
import useStatut from 'hooks/useStatut';
import { useNavigate } from 'react-router-dom';

const statusMap = {
  new: 'warning',
  take_charge: 'success',
  without_continuation: 'error'
};

export const LastSignalement = (props) => {
  const { signalements = [], sx, title, seeMore } = props;
  const router = useNavigate();
  const { label, color } = useStatut();

  return (
    <Card sx={sx}>
      <CardHeader title={title} sx={{backgroundColor: FANCY_BLUE, color:"white"}} />
      <Scrollbar sx={{ flexGrow: 1 }}>
        <Box sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Ticket
                </TableCell>
                <TableCell>
                  Date
                </TableCell>
                <TableCell sortDirection="desc">
                  Utilisateur
                </TableCell>
                <TableCell>
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signalements.map((signalement) => {
                const createdAt = signalement.createdAt;

                return (
                  <TableRow
                    hover
                    key={signalement.id}
                  >
                    <TableCell>
                      {signalement.ticket}
                    </TableCell>
                    <TableCell>
                      {signalement.date}
                    </TableCell>
                    <TableCell>
                      {signalement.user}
                    </TableCell>
                    <TableCell>
                      {createdAt}
                    </TableCell>
                    <TableCell>
                      <ThemeProvider theme={theme}>
                      <KjosChip label={signalement.statut} variant="filled" color={color(signalement.statut)}/>
                      </ThemeProvider>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Button
          color="inherit"
          endIcon={(
            <SvgIcon fontSize="small">
              <ArrowRightIcon />
            </SvgIcon>
          )}
          size="small"
          variant="text"
          onClick={() => {
            router(seeMore);
          }}
        >
          Voir tout
        </Button>
      </CardActions>
    </Card>
  );
};

export default LastSignalement