export const MAIN_BLUE = "#00308F";
export const MAIN_GREEN = "#15B79E";
export const MAIN_GRAY = "#F6F7FA";
export const FANCY_ORANGE = "#f8b64d";
export const FANCY_GREEN = "#59d8b6";
export const FANCY_BLUE = "#4099ff";
export const FANCY_PINK = "#f05170";
export const BG_COLOR = "#f2f7fb";

export const PENDDING_COLOR = "#f8b64d";
export const NEW_COLOR = "#59d8b6";
export const OPEN_COLOR = "#4099ff";
export const DELIVERY_COLOR = "#f05170";
export const WITHOUT_CONTINUATION_COLOR = "#47bcd3";

export const ERROR_COLOR = "red";
export const CREATE_COLOR = "#59d8b6";
export const UPDATE_COLOR = "#4099ff";
export const DELETE_COLOR = "#f8b64d";