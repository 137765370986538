import Pusher from "pusher-js";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Divider, MenuItem, MenuList, Popover, SvgIcon, Typography } from "@mui/material";
import useDeconnect from "hooks/useDeconnect";
import { useDispatch, useSelector } from "react-redux";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import { Stack } from "@mui/system";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import ArrowLeftOnRectangleIcon from "@heroicons/react/24/solid/ArrowLeftOnRectangleIcon";
import useUser from "hooks/useUser";
import useHttp from "hooks/useHttp";
import { ADMIN_NOTIFICATION_URL, ADMIN_USER_URL } from "config/RoutesUrl";
import { useEffect, useState } from "react";
import { PLAINTES_DETAIL_PATH, SIGNALEMENT_DETAIL_URL } from "config/PathUrl";
import EmailIcon from "@mui/icons-material/Email";
import DraftsIcon from "@mui/icons-material/Drafts";
import { setCountNotification } from "store/SignalementReducer";
import getTimeElapsedString from "controller/getTimeElapsedString";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const NotificationsPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const router = useNavigate();
  const { getUser, updateUser } = useUser();
  const { getData, putData } = useHttp();
  const [notifications, setNotifications] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [endMessage, setEndMessage] = useState(null);
  const dispatch = useDispatch();
  const incrementPage = (callback) => {
    setPage((prevPage) => {
      const nextPage = prevPage + 1;
      callback(nextPage);
      return nextPage;
    });
  };

  if ("Notification" in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
      } else {
      }
    });
  }

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    if (scrollTop + clientHeight === scrollHeight) {
      setLoading(true);

      incrementPage((nextPage) => {
        getData(ADMIN_NOTIFICATION_URL + `?page=${nextPage}&per_page=${perPage}&admin_id=${getUser("admin")?.user?.id}`)?.then((res) => {
          if (Object.keys(res).length === 0) {
            setEndMessage("Aucune notification");
          } else {
            setNotifications((prevNotifications) => [...prevNotifications, ...res?.data]);
            setEndMessage(null);
          }
        });
      });

      setLoading(false);
      // End of scroll reached, run your function here
    }
  };

  const handleMenuClick = async (notification) => {
    setUpdate(true);
    setPage(1);
    await putData({ open: true }, ADMIN_NOTIFICATION_URL + `/${notification.id}`);
    notification.signalement_id ? router(SIGNALEMENT_DETAIL_URL, { state: { id: notification.signalement_id } }) : notification.plainte_id ? router(PLAINTES_DETAIL_PATH, { state: { id: notification.plainte_id } }) : null;
  };

  useEffect(() => {
    setPage(1);
    getData(ADMIN_NOTIFICATION_URL + `?page=${page}&per_page=${perPage}&admin_id=${getUser("admin")?.user?.id}`)?.then((res) => {
      setNotifications(res?.data);
      dispatch(setCountNotification(res?.countNotOpen));
      setUpdate(false);
    });
  }, [update]);

  useEffect(() => {
    // Enable pusher logging - don't include this in production
    Pusher.logToConsole = true;

    var pusher = new Pusher("34ec8f96855782769ce0", {
      cluster: "eu",
    });

    var channel = pusher.subscribe("my-channel");
    channel.bind("my-event", function (data) {
      let value = JSON.stringify(data)
      value = JSON.parse(value)
      if (Notification.permission === "granted") {
        new Notification(value?.message);
      }

      setPage(1);
      setTimeout(() => {
        getData(ADMIN_NOTIFICATION_URL + `?page=${page}&per_page=${perPage}&admin_id=${getUser("admin")?.user?.id}`)?.then((res) => {
          setNotifications(res?.data);
          dispatch(setCountNotification(res?.countNotOpen));
        });
      }, 5000);
    });

    return () => {
      pusher.unsubscribe("my-channel");
    };
  }, []);
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      anchorPosition={{ vertical: 500, horizontal: 1000 }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: "25%", height: "20rem", overflowY: "auto" }, onScroll: handleScroll }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Notifications</Typography>
      </Box>
      <Divider />
      {/* Map 100 */}

      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        {" "}
        {Array.isArray(notifications) &&
          notifications.map((notification, index) => (
            <MenuItem onClick={() => handleMenuClick(notification)} key={index}>
              {notification.message && (
                <Stack direction={"row"} spacing={2} sx={{ width: "100%" }}>
                  {notification.open ? (
                    <SvgIcon fontSize="small">
                      <DraftsIcon />
                    </SvgIcon>
                  ) : (
                    <SvgIcon fontSize="small">
                      <EmailIcon />
                    </SvgIcon>
                  )}
                  <Stack direction={"column"} sx={{ width: "100%" }}>
                    {notification.message && <Typography sx={{ fontWeight: notification.open ? "normal" : "bold", whiteSpace: "pre-wrap", overflow: "hidden", textOverflow: "ellipsis" }}>{notification.message}</Typography>}

                    <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                      <SvgIcon fontSize="small">
                        <AccessTimeIcon />
                      </SvgIcon>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        {getTimeElapsedString(new Date(notification.created_at))}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              )}
            </MenuItem>
          ))}
        {loading && (
          <MenuItem>
            {" "}
            <Typography>Chargement...</Typography>
          </MenuItem>
        )}
        {endMessage && (
          <MenuItem>
            <Typography>{endMessage}</Typography>
          </MenuItem>
        )}
      </MenuList>
    </Popover>
  );
};

export default NotificationsPopover;
