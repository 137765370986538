/* eslint-disable react/jsx-max-props-per-line */
import { Button, Snackbar, Stack } from "@mui/material"
import MuiAlert from '@mui/material/Alert';
import { useState } from "react";
import { useEffect } from "react";

const KjosActionButton = (props) => {
const {
   onclickSave,
   onclickCancel,
   labelSave,
   labelCancel, 
   colorSave,
   colorCancel,
   isClicked
} = props

const [open, setOpen] = useState(false);
const handleClose = (event, reason) => {
   if (reason === 'clickaway') {
     return;
   }

   setOpen(false);
 };

useEffect(() => {
  setOpen(isClicked)
}, [isClicked, open])

   return (
      <>
      <Stack direction={"row"} spacing={2}>
         <Button variant="contained" onClick={onclickSave} color={colorSave} >{labelSave}</Button>
         <Button variant="contained" onClick={onclickCancel} color={colorCancel}>{labelCancel}</Button>
      </Stack>

      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} >
        <MuiAlert onClose={handleClose}  severity="success" sx={{ width: '100%' }}>
          This is a success message!
        </MuiAlert>
      </Snackbar>
      </>
   )
}

export default KjosActionButton