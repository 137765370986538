import { useState } from "react";
import useHttp from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Card, CardActions, CardContent, CardHeader, Divider, Unstable_Grid2 as Grid, FormControlLabel, Checkbox, Typography } from "@mui/material";
import useUser from "hooks/useUser";
import useRegister from "hooks/useRegister";
import KjosInput2 from "components/KjosInput2";
import KjosSnacBar from "components/KjosSnackBar";
import { useRef } from "react";
import { BG_COLOR, FANCY_BLUE, FANCY_GREEN, MAIN_BLUE, MAIN_GRAY, UPDATE_COLOR } from "config/color";
import { useEffect } from "react";
import translatePermission from "controller/translatePermission";
import usePermission from "hooks/usePermission";
import { useLocation } from "react-router-dom";
import KjosAlert from "components/KjosAlert";
import { setAlert } from "store/SignalementReducer";
import KjosTitle from "components/KjosTitle";
import { Lock } from "@mui/icons-material";
import scrollToTop from "controller/scrollToTop";

// const permissionsArray = [
//   "create_signalement",
//   "read_signalement",
//   "update_signalement",
//   "delete_signalement",
//   "read_historique",
//   "delete_historique",
//   "update_historique",
//   "read_comment",
//   "create_comment",
//   "delete_comment",
//   "update_comment",
//   "transfert_signalement",
//   "class_without_continuation",
//   "class_without_continuation_callback",
//   "create_user",
//   "read_user",
//   "update_user",
//   "delete_user",
//   "create_habilete",
//   "read_habilete",
//   "update_habilete",
//   "delete_habilete",
//   "create_admin",
//   "read_admin",
//   "update_admin",
//   "delete_admin",
//   "create_permission",
//   "read_permission",
//   "update_permission",
//   "delete_permission",
//   "create_role",
//   "read_role",
//   "update_role",
//   "delete_role",
//   "export_rapport",
//   "all_permission",
//   "show_dashboard",
//   "admin_settings",
// ];

const HabiletesEdit = () => {
  const { handleFormSubmit } = useRegister();
  const formRef = useRef();
  const { getAllPermissions, showHabiletes, AssignPermissions } = usePermission(); // get data from server
  const { page, rowsPerPage } = useSelector((state) => state.signalement);
  const { getUser, updateUser } = useUser();
  const [postUser, setPostUser] = useState([]);
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [habilletes, setHabilletes] = useState([]);
  const router = useLocation();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const register = useRef();
  const dispatch = useDispatch();
  const handleLocalFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget); // get form data

    if (router.state?.id === "" || router.state?.id === undefined) {
      dispatch(setAlert({ open: true, message: "Erreur inconue", color: "error" }));
      return;
    }

    const data = {
      habilete_id: router.state?.id,
      selectedPermissions: selectedPermissions,
      oldPermissions: habilletes?.permissions,
    };
    // console.log(data)
    // return

    const assignPermissions = await AssignPermissions(data);
    if (assignPermissions.error) {
      // setSnackbar({
      //   open: true,
      //   message: "unse erreur c'est produite",
      //   severity: "error",
      //   autoHideDuration: 5000,
      // });
      dispatch(setAlert({ open: true, message: "Une erreur inconu s'est produite, veuillez contacter l'administrateur si cela persiste.", color: "error" }));
    } else {
      // setSnackbar({
      //   open: true,
      //   message: "Succès",
      //   severity: "success",
      //   autoHideDuration: 5000,
      // });
      dispatch(setAlert({ open: true, message: "Permission mise à jour avec succès", color: UPDATE_COLOR }));
    }
    scrollToTop();
  };

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  useEffect(() => {
    showHabiletes(router.state.id).then((habiletes) => {
      if (habiletes?.error) {
        
      } else {
        setHabilletes(habiletes?.data);
      }
    });

    getAllPermissions().then((response) => {
      setPermissionsArray(response);
    });
  }, [getAllPermissions, router.state.id, showHabiletes]);

  useEffect(() => {
    habilletes?.permissions?.map((permission) => {
      setSelectedPermissions((prevSelected) => [...prevSelected, permission]);
    });
  }, [habilletes?.permissions]);

  useEffect(() => {
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, []);

  return (
    <form onSubmit={handleLocalFormSubmit} ref={formRef}>
      <KjosAlert label={alert.message} />

      <Grid container sx={{ backgroundColor: BG_COLOR }}>
        <Grid xs={12}>
          <Box sx={{ m: 5 }}>
            <Grid container spacing={3}>
              <Grid xs={5}>
                <KjosInput2 type="text" name="habilete_id" id="habilete_id" label="Groupe" disabled={true} width={"100%"} defaultValue={habilletes?.libelle || ""} />
              </Grid>

              <Divider sx={{ color: "black" }} />

              <Grid xs={12}>
                <KjosTitle icon={<Lock />} title="Autorisations" subtitle="Cocher les autorisations à attribuer aux différents groupe" iconBgColor={FANCY_BLUE} />
                <br />
              </Grid>
            </Grid>
            <Card>
              <CardContent sx={{ p: 5 }}>
                <Grid container spacing={3} sx={{ backgroundColor: "white" }}>
                  {typeof permissionsArray === "object" &&
                    permissionsArray !== null &&
                    Object.keys(permissionsArray)
                      .sort((a, b) => permissionsArray[b].length - permissionsArray[a].length)
                      .map((permissionKey, i) => {
                        const permission = permissionsArray[permissionKey];
                        return (
                          <>
                            <Grid xs={12} key={i}>
                              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                                {/* Use permissionKey to determine the label */}
                                {permissionKey === "admin" ? "Gestion des Administrateurs" : permissionKey === "user" ? "Gestion des Utilisateurs" : permissionKey === "articles" ? "Gestion des Articles" : permissionKey === "habilete" ? "Gestion des Habilites" : permissionKey === "permission" ? "Gestion des Permissions" : permissionKey === "class_without" ? "Status Classé sans suite" : permissionKey === "CMS" ? "Gestion CMS" : permissionKey === "complaints" ? "Gestion des Plaintes" : permissionKey === "role" ? "Gestion des rôles" : permissionKey === "signalement" ? "Gestion des Signalements" : permissionKey === "comment" ? "Gestion des commentaires" : permissionKey === "rapport" || permissionKey === "report" ? "Gestion des rapports" : permissionKey === "historique" ? "Gestion de l'historique" : permissionKey === "dashboard" ? "Gestion du tableau de bord" : ""}
                              </Typography>
                            </Grid>
                            {permission?.map((item, index) => {
                              return (
                                <Grid xs={6} md={4} key={index}>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        // checked={habilletes?.permissions?.includes(permission.libelle)}
                                        name={"permissions"}
                                        value={item?.id?.libelle}
                                        defaultChecked={habilletes?.permissions?.includes(item?.id?.libelle)}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setSelectedPermissions((prevSelected) => [...prevSelected, item?.id?.libelle]);
                                          } else {
                                            setSelectedPermissions((prevSelected) => prevSelected.filter((p) => p !== item?.id?.libelle));
                                          }
                                        }}
                                        ref={register}
                                      />
                                    }
                                    label={translatePermission(item?.id?.libelle)}
                                  />
                                </Grid>
                              );
                            })}
                          </>
                        );
                      })}
                </Grid>
              </CardContent>
              <Divider />
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                  Enregistrer
                </Button>
              </CardActions>
            </Card>

            {postUser?.error && (
              <Typography color="error" sx={{ mt: 3 }} variant="body2">
                {postUser?.message}
              </Typography>
            )}
          </Box>

          {/* <KjosSnacBar open={snackbar?.open} message={snackbar?.message} severiry={snackbar?.severity} onClose={handleOncloseSanackbar} autoHideDuration={snackbar?.autoHideDuration} /> */}
        </Grid>
      </Grid>
    </form>
  );
};
export default HabiletesEdit;
