import { FileUpload } from "@mui/icons-material";
import { Camera } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import { Stack } from "@mui/system";
import { useEditor } from "@tiptap/react";
import KjosInputFile2 from "components/KjosInputFile2";
import StarterKit from "@tiptap/starter-kit";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Gapcursor from "@tiptap/extension-gapcursor";
import { MenuButtonBold, MenuButtonItalic, MenuControlsContainer, MenuDivider, MenuSelectHeading, RichTextEditorProvider, RichTextField, MenuButtonBlockquote, MenuButtonBulletedList, MenuButtonTextColor, MenuButtonUnderline, MenuButtonAddTable, TableBubbleMenu, MenuButtonOrderedList } from "mui-tiptap";

import { useState } from "react";
import { useRef } from "react";

export default function KjosRichText(props) {
  const { onUpdate, fileRef, audioRef, videoRef, defaultContent } = props;


  const editor = useEditor({
    extensions: [StarterKit, Color, TextStyle, Underline, TableRow, TableHeader, TableCell, Gapcursor,
      Table.configure({
        resizable: true,
      }),],
    content: defaultContent,
    onUpdate({ editor }) {
      onUpdate(editor.getHTML());
    },
  });
  const [file, setFile] = useState({ message: "", file: null });
  const [audio, setAudio] = useState({ message: "", file: null });
  const [video, setVideo] = useState({ message: "", file: null });

  const handleFileUpload = (message, file) => {
    setFile({ message: message, file: file });
  };

  const handleAudioUpload = (message, file) => {
    setAudio({ message: message, file: file });
  };

  const handleVideoUpload = (message, file) => {
    setVideo({ message: message, file: file });
  };

  const fileRef1 = useRef();
  const audioRef1 = useRef();
  const videoRef1 = useRef();

  return (
    <RichTextEditorProvider editor={editor}>
      <RichTextField
        controls={
          <MenuControlsContainer>
            <Stack direction="column" sx={{bgColor: "red"}}>
              <Stack direction="row" spacing={1} alignItems={"center"} flexWrap={"wrap"}>
                {" "}
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonBold />
                <MenuButtonItalic />
                <MenuButtonBlockquote />
                <MenuButtonOrderedList />
                <MenuButtonBulletedList />
                <MenuButtonTextColor defaultTextColor="#000" />
                <MenuButtonUnderline />
                <MenuButtonAddTable />
                <TableBubbleMenu />
                {/* Add more controls of your choosing here */}
                <KjosInputFile2
                  photoRef={fileRef || fileRef1}
                  onChange={handleFileUpload}
                  icon={<FileUpload sx={{ fontSize: "1rem" }} />}
                />
                <KjosInputFile2
                  photoRef={audioRef || audioRef1}
                  onChange={handleAudioUpload}
                  icon={<AudioFileIcon sx={{ fontSize: "1rem" }} />}
                  accept="audio/*"
                />
                <KjosInputFile2
                  photoRef={videoRef || videoRef1}
                  onChange={handleVideoUpload}
                  icon={<VideoLibraryIcon sx={{ fontSize: "1rem" }}/>}
                  accept="video/*"
                />
              </Stack>
              {file.message && file.message}
              {audio.message && audio.message}
              {video.message && video.message}
            </Stack>
          </MenuControlsContainer>
        }
      />
    </RichTextEditorProvider>
  );
}
