import axios from "axios";
import { BASE_URL, BASE_URL_API, CSRF_COOKIES, LOGIN_URL } from "config/RoutesUrl";
import {
  setAuth,
  setDeleteResult,
  setEditResult,
  setGetResult,
  setOneDataResult,
  setPostResult,
  setUser,
  seterror,
  setloading,
} from "store/AuthenticationReducer";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useLocalSorage from "./useLocalSorage";
import { DASHBOARD_PATH, RESET_PASSWORD_PATH, SIGNIN_PATH, SIGNUP_PATH } from "config/PathUrl";
import { useState } from "react";
import { useEffect } from "react";
import KjosHttp from "controller/KjosHttp";
import { useDispatch, useSelector } from "react-redux";

function useHttp(tokenUrl = BASE_URL + CSRF_COOKIES, item = "admin") {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const route = {
    signin: SIGNIN_PATH,
    signup: SIGNUP_PATH,
    resetPassword: RESET_PASSWORD_PATH,
  };
  // const [datas, setDatas] = useState(null);
  const { datas } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [getLocalStorage, removeLocalStorage] = useLocalSorage(); // get local storage hook

  const postData = useCallback(
    async (data, url, config = null, defaulConfig = true) => {
      var configGlobal = config;
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      if (defaulConfig) {
        const token = getLocalStorage(item)?.token; // get token
        const c = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
        configGlobal = c;
      }

      try {
        const fetcher = await new KjosHttp().post(url, data, configGlobal);

        if (fetcher?.error?.response.status === 401) {
          return {
            error: true,
            data: fetcher?.error?.response?.data,
          };
        } else if (fetcher?.status === 200) {
          dispatch(setPostResult(fetcher?.data));
          return fetcher?.data;
        } else {
          dispatch(setPostResult(fetcher?.error?.response?.data));
          return fetcher?.error?.response?.data;
        }
      } catch (error) {
      }
    },
    [dispatch, getLocalStorage, item]
  );

  const getData = useCallback(
    async (url) => {
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      const token = getLocalStorage(item)?.token; // get token
      const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
      try {
        const fetcher = await new KjosHttp().get(url, config);
        if (fetcher?.error?.response.status === 401) {
          return {
            error: true,
            data: fetcher?.error?.response?.data,
          };
        } else if (fetcher?.status === 200) {
          // setDatas(fetcher?.data);
          dispatch(setGetResult(fetcher?.data));
          return fetcher?.data;
        } else {
        }
      } catch (error) {
      }
    },
    [dispatch, getLocalStorage, item]
  );

  const putData = useCallback(
    async (data, url, config = null, defaulConfig = true) => {
      var configGlobal = config;
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      if (defaulConfig) {
        const token = getLocalStorage(item)?.token; // get token
        const c = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
        configGlobal = c;
      }
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      try {
        const fetcher = await new KjosHttp().put(url, data, configGlobal);

        if (fetcher?.error?.response.status === 401) {
          return {
            error: true,
            data: fetcher?.error?.response?.data,
          };
        } else if (fetcher?.status === 200) {
          dispatch(setEditResult(fetcher?.data));
          return fetcher?.data;
        } else {
          dispatch(setEditResult(fetcher?.error?.response?.data));
          return fetcher?.error?.response?.data;
        }
      } catch (error) {
      }
    },
    [dispatch, getLocalStorage, item]
  );

  const deleteData = useCallback(
    async (data, url) => {
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      const token = getLocalStorage(item)?.token; // get token
      const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
      data?.forEach(async (item) => {
        try {
          const fetcher = await new KjosHttp().delete(url + "/" + item, config);
          if (fetcher?.error?.response.status === 401) {
            return {
              error: true,
              data: fetcher?.error?.response?.data,
            };
          } else if (fetcher?.status === 200) {
            dispatch(setDeleteResult(fetcher?.data));
            return fetcher?.data;
          } else {
            dispatch(setDeleteResult(fetcher?.error?.response?.data));
            return fetcher?.error?.response?.data;
          }
        } catch (error) {
        }
      });
    },
    [dispatch, getLocalStorage, item]
  );

  const deleteData2 = useCallback(
    async (url) => {
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      const token = getLocalStorage(item)?.token; // get token
      const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config

      try {
        const fetcher = await new KjosHttp().delete(url, config);
        if (fetcher?.status === 200) {
          return fetcher?.data;
        } else {
          return fetcher?.error?.response?.data;
        }
      } catch (error) {
      }
    },
    [getLocalStorage, item]
  );

  const getOneData = useCallback(
    async (id, url) => {
      // const formData = new FormData(param.currentTarget); // get form data
      // get form data
      const token = getLocalStorage(item)?.token; // get token
      const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config

      try {
        const fetcher = await new KjosHttp().get(url + "/" + id, config);
        if (fetcher?.error?.response.status === 401) {
          return {
            error: true,
            data: fetcher?.error?.response?.data,
          };
        } else if (fetcher?.status === 200) {
          dispatch(setOneDataResult(fetcher?.data));
          return fetcher?.data;
        } else {
          dispatch(setOneDataResult(fetcher?.error?.response?.data));
          return fetcher?.error?.response?.data;
        }
      } catch (error) {
      }
    },
    [dispatch, getLocalStorage, item]
  );

  useEffect(() => {
    return () => {
      dispatch(setPostResult(null));
      dispatch(setGetResult(null));
      dispatch(setEditResult(null));
      dispatch(setDeleteResult(null));
      dispatch(setOneDataResult(null));
    };
  }, [dispatch, error, loading]);

  return { postData, getData, putData, deleteData, getOneData, deleteData2 };
}

export default useHttp;
