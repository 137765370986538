import { useCallback, useState } from "react";
import useHttp from "hooks/useHttp";
import { useDispatch, useSelector } from "react-redux";
import { ADMIN_USER_URL, ADMIN_HABILETE_URL, ADMIN_COMMUNE_URL } from "config/RoutesUrl";
import formatPhoneNumber from "controller/formatPhoneNumber";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  MenuItem,
  Typography,
  Stack,
  Chip,
} from "@mui/material";
import useUser from "hooks/useUser";
import KjosInput2 from "components/KjosInput2";
import KjosSnacBar from "components/KjosSnackBar";
import KjosInputImage from "components/KjosInputImage";
import { avatar_anonyme } from "config/image";
import { useRef } from "react";
import { FANCY_GREEN, MAIN_BLUE, MAIN_GRAY, UPDATE_COLOR } from "config/color";
import getDefaultImage from "controller/getDefaultImage";
import KjosSelect4 from "components/KjosSelect4";
import { useEffect } from "react";
import { setAlert } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import KjosChip from "components/KjosChip";
import scrollToTop from "controller/scrollToTop";

const AdminDetail = (props) => {
  const { user } = props;
  const photoRef = useRef();
  const photoCleanedRef = useRef(false);
  const { putData, getData } = useHttp(); // get data from server
  const [postUser, setPostUser] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
const dispatch = useDispatch()
  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.currentTarget); // get form data
    const formData2 = new FormData();
    let contact = formData.get("contact");
        contact = contact.replace(/[^\d]/g, '');

    formData2.append("nom", formData.get("nom"));
    formData2.append("prenom", formData.get("prenom"));
    formData2.append("contact", contact);
    formData2.append("ville", formData.get("ville"));
    formData2.append("role", "admin");
    formData2.append("pays", formData.get("pays"));
    formData2.append("habilete_id", formData.get("habilete_id"));
    formData2.append("date_naissance", formData.get("date_naissance"));
    formData2.append("genre", formData.get("genre"));
    formData2.append("commune_id", formData.get("commune_id"));

    // Si on change de photo
    if (!photoCleanedRef.current && photoRef.current.files[0]) {
      formData2.append("photo", photoRef.current.files[0]);
      formData2.append("photo_changed", "changed");
    }

    // Si on supprime photo
    if (photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("photo", "cleaned");
      formData2.append("photo_changed", "changed");
    }

    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("photo_changed", "unchanged");
    }

  
    const postUs = await putData(formData2, ADMIN_USER_URL + "/" + user?.id);

    if (postUs?.error) {
      // setSnackbar({
      //   open: true,
      //   message: "Erreur lors de l'enregistrement du signalement",
      //   severity: "error",
      //   autoHideDuration: 5000,
      // });

      dispatch(setAlert({ open: true, message: "Erreur lors de l'enregistrement, veuillez reessayer. si le problème persiste, contactez l'administrateur", color: "error" })); 
    } else {
      // setSnackbar({
      //   open: true,
      //   message: "Vos informations ont bien été mises à jour",
      //   severity: "success",
      //   autoHideDuration: 5000,
      // });

      dispatch(setAlert({ open: true, message: "Mise à jour effectuée avec sucès.", color: UPDATE_COLOR }));

    }
    scrollToTop();
    setPostUser(postUs);
  };

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };
  const [habilletes, setHabilletes] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [gender, setGender] = useState("");

  const handleRadioChange = (event) => {
    setGender(event.target.value);
  };
 
  useEffect(() => {
    console.log(user)
    setGender(user?.genre);
    async function fetchData() {
      const hab = (await getData(ADMIN_HABILETE_URL))?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      setHabilletes(hab);

      const com = (await getData(ADMIN_COMMUNE_URL))?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      setCommunes(com);
    }
    fetchData();
  }, [getData, user?.genre]);

  useEffect(() => {
    return (() => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        }))
    })
  },[])

  return (
    <form onSubmit={handleFormSubmit}>
      <KjosAlert label={alert.message} />
      <Grid container spacing={3}>
        <Grid xs={12} md={6} lg={4}>
          <Card sx={{ bgcolor: MAIN_GRAY, height: "100%" }}>
          <CardHeader
              subheader="Veuillez choisir une pohto"
              title={
                "Photo"
              }
              sx={{backgroundColor: FANCY_GREEN, color: "white"}}
            />
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <KjosInputImage
                  photoRef={photoRef}
                  source={getDefaultImage(user?.photo)}
                  defaultImage={avatar_anonyme}
                  photoCleanedRef={photoCleanedRef}
                />

                {/* <Typography
        gutterBottom
        variant="h5"
      >
        {user.name}
      </Typography> */}
                {/* <Typography
        color="text.secondary"
        variant="body2"
      >
        {user.city} {user.country}
      </Typography> */}
                {/* <Typography
        color="text.secondary"
        variant="body2"
      >
        {user.timezone}
      </Typography> */}
                <Typography mt={2} variant="body2">
                  10Mo max (jpg, png)
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid xs={12} md={6} lg={8}>
          <Card sx={{ bgcolor: MAIN_GRAY }}>
            <CardHeader
              subheader="Mettre à jour vos informations personnelles"
              title={
                <div>
                  Profil{" "}
                  <KjosChip
                    label={user?.statut}
                    color={user?.statut === "actif" ? "success" : "error"}
                    size="small"
                    variant="contained"

                  />
                </div>
              }
              sx={{backgroundColor: FANCY_GREEN, color: "white"}}

            />
            <CardContent sx={{ pt: 0 }}>
              <Box sx={{ m: -1.5 }}>
                <Grid container spacing={3}>
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="text"
                      name="nom"
                      id="nom"
                      label="Nom"
                      required
                      width={"100%"}
                      defaultValue={user?.nom}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="text"
                      name="prenom"
                      id="prenom"
                      label="Prénom"
                      required
                      width={"100%"}
                      defaultValue={user?.prenom}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="email"
                      label="Adresse Email"
                      name="email"
                      id="email"
                      required
                      width={"100%"}
                      defaultValue={user?.email}
                      disabled
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      id="contact" 
                      label="Numéro de téléphone"
                      name="contact"
                      type="text"
                      required
                      width={"100%"}
                      value={phoneNumber === null ? formatPhoneNumber(user?.contact) : phoneNumber }
                      onChange={(event) => {
                        setPhoneNumber(formatPhoneNumber(event));
                      }}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="text"
                      id="pays"
                      label="Pays"
                      name="pays"
                      width={"100%"}
                      defaultValue={user?.pays}
                    />
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="text"
                      id="ville"
                      label="Ville"
                      name="ville"
                      width={"100%"}
                      defaultValue={user?.ville}
                    />
                  </Grid>

                  <Grid xs={12} md={6}>
                  <KjosSelect4
                      id="commune_id"
                      label="Localité"
                      name="commune_id"
                      width={"100%"}
                      defaultValue={user?.commune?.id }
                      options={communes}
                    />
                  </Grid>

                  <Grid xs={12} md={6}>
                    <KjosInput2
                      type="date"
                      id="date_naissance"
                      label="Date de naissance"
                      name="date_naissance"
                      width={"100%"}
                      defaultValue={user?.date_naissance?.split(" ")[0]}
                    />
                  </Grid>

                  <Grid xs={12} md={6} alignItems={"center"}>
                    <Typography variant="body2">Genre</Typography>
                    <Stack direction="row" spacing={8}>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="body2">Masculin</Typography>
                        <input
                          type="radio"
                          id="homme"
                          name="genre"
                          value={"homme"}
                          checked={gender == "homme" ? true : false}
                          onChange={handleRadioChange}
                        />
                      </Stack>
                      <Stack direction="column" spacing={1}>
                        <Typography variant="body2">Feminin</Typography>
                        <input
                          type="radio"
                          id="femme"
                          name="genre"
                          value={"femme"}
                          checked={gender == "femme" ? true : false}
                          onChange={handleRadioChange}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid xs={12} md={6}>
                    <KjosSelect4
                      id="habilete_id"
                      label="Habilitation"
                      name="habilete_id"
                      width={"100%"}
                      defaultValue={user?.habilete?.id }
                      options={habilletes}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ justifyContent: "flex-end" }}>
              <Button type="submit" variant="contained" sx={{ bgcolor: FANCY_GREEN }}>
                Enregistrer
              </Button>
            </CardActions>
          </Card>

          {postUser?.error && (
            <Typography color="error" sx={{ mt: 3 }} variant="body2">
              {postUser?.message}
            </Typography>
          )}

          <KjosSnacBar
            open={snackbar?.open}
            message={snackbar?.message}
            severiry={snackbar?.severity}
            onClose={handleOncloseSanackbar}
            autoHideDuration={snackbar?.autoHideDuration}
          />
        </Grid>
      </Grid>
    </form>
  );
};
export default AdminDetail;
