import { DELIVERY_COLOR, NEW_COLOR, OPEN_COLOR, PENDDING_COLOR, WITHOUT_CONTINUATION_COLOR } from "config/color";
import { useCallback } from "react";

export default function useStatut() {
  const color = useCallback((statut) => {
    if (statut === "new" || statut === "actif" || statut === undefined || statut === null || statut === "") {
      return NEW_COLOR;
    } else if (statut === "Nouveau") {
      return NEW_COLOR;
    } 
    else if(statut === "En cours"){
      return PENDDING_COLOR;
    }
    else if(statut === "Ouvert"){
      return OPEN_COLOR;
    }
    else if(statut === "Sans suite"){
      return WITHOUT_CONTINUATION_COLOR;
    }
    else if(statut === "Traité"){
      return DELIVERY_COLOR;
    }
    else if (statut === "pendding" || statut === "inactif") {
      return PENDDING_COLOR;
    } else if (statut === "open" || statut === "take_charge") {
      return OPEN_COLOR;
    } else if (statut === "without_continuation") {
      return WITHOUT_CONTINUATION_COLOR;
    } else if (statut === "delivery") {
      return DELIVERY_COLOR;
    } 
    else {
      
      return "primary"
    }
  }, []);

  const label = useCallback((statut) => {
    if (statut === "new" || statut === null || statut === undefined || statut === "" || statut === "actif") {
      return "Nouveau";
    } else if (statut === "pendding") {
      return "En cours";
    } else if (statut === "open") {
      return "Ouvert";
    } else if (statut === "without_continuation") {
      return "Sans suite";
    } else if (statut === "delivery") {
      return "Traité";
    } 
    else if (statut === "take_charge") {
      return "Pris en charge";
    }
    else {
      // return "primary"
    }
  }, []);

  return { label, color };
}
