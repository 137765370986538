import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import stringToHtml from "controller/stringToHtml";
import getCommentTypeLabel from "controller/getCommentTypeLabel";
import getFileTypeFromUrl from "controller/getFileTypeFromUrl";
import getDefaultImage from "controller/getDefaultImage";

import { Stack } from "@mui/material";
import styled from "styled-components";
import { Link } from "react-router-dom";

const AudioControlContainer = styled.div`
width: 200px;
height: 50px;
`;

const AudioControl = styled.audio`
width: 100%;
height: 100%;
`;

export default function KjosSttepper(props) {
  const { steps } = props;
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  React.useEffect(() => {
  }, []);

  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps?.map((step, index) => (
          <Step key={index} active={true}>
            <StepLabel>{step?.date}</StepLabel>
            <StepContent>
              <Typography sx={{ fontWeight: "bold" }}>{getCommentTypeLabel(step?.type)}</Typography>
              <Typography> {stringToHtml(step?.libelle)}</Typography>
              <Stack
                direction={"column"}
                spacing={1}
                alignItems={"start"}
                justifyContent={"center"}
              >
                {/* file */}
                {(step?.file === null || step?.file === "null")? "" :
                  (getFileTypeFromUrl(step?.file) === "image" ? (
                    <Link to={getDefaultImage(step?.file) ?? ""} target="_blank">
                      <Typography variant="body">Pièce jointe 1</Typography>
                    </Link>
                  ) : getFileTypeFromUrl(step?.file) === "audio" ? (
                    <AudioControlContainer>
                      <AudioControl controls>
                        <source src={getDefaultImage(step?.file)} type="audio/ogg" />
                        Your browser does not support the audio element.
                      </AudioControl>
                    </AudioControlContainer>
                  ) : (
                    <Link to={getDefaultImage(step?.file) ?? ""} target="_blank">
                      <Typography variant="body">Pièce jointe 1</Typography>
                    </Link>
                  ))}

                {/* audio */}
                {(step?.audio === null || step?.audio === "null")? "" : (
                  <AudioControlContainer>
                    <AudioControl controls>
                      <source src={getDefaultImage(step?.audio)} type="audio/ogg" />
                      Your browser does not support the audio element.
                    </AudioControl>
                  </AudioControlContainer>
                )}

                {/* video */}
                {(step?.video === null || step?.video === "null")? "" : (
                  <Link to={getDefaultImage(step?.video) ?? ""} target="_blank">
                    <Typography variant="body">Fichier vidéo</Typography>
                  </Link>
                )}
              </Stack>

              <Box sx={{ mb: 2 }}>{/* ... */}</Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {/* ... */}
    </Box>
    // <Box sx={{ maxWidth: 400 }}>
    //   <Stepper activeStep={activeStep} orientation="vertical">
    //     {steps?.map((step, index) => (
    //       <Step key={index}>
    //         <StepLabel
    //           // optional={
    //           //   index === 2 ? (
    //           //     ""
    //           //     // <Typography variant="caption">Dernière étape</Typography>
    //           //   ) : null
    //           // }
    //         >
    //           {step?.date}
    //         </StepLabel>
    //         <StepContent>
    //           <Typography>{step?.type}</Typography>
    //           <Typography> {stringToHtml(step?.libelle)}</Typography>
    //           <Box sx={{ mb: 2 }}>
    //             <div>
    //               {/* <Button
    //                 variant="contained"
    //                 onClick={handleNext}
    //                 sx={{ mt: 1, mr: 1 }}
    //               >
    //                 {index === steps.length - 1 ? 'Terminer' : 'Continuer'}
    //               </Button> */}
    //               {/* <Button
    //                 disabled={index === 0}
    //                 onClick={handleBack}
    //                 sx={{ mt: 1, mr: 1 }}
    //               >
    //                 Retour
    //               </Button> */}
    //             </div>
    //           </Box>
    //         </StepContent>
    //       </Step>
    //     ))}
    //   </Stepper>
    //   {activeStep === steps.length && (
    //     <Paper square elevation={0} sx={{ p: 3 }}>
    //       <Typography>Aucune Etape</Typography>
    //       {/* <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
    //         Réinitialiser
    //       </Button> */}
    //     </Paper>
    //   )}
    // </Box>
  );
}
