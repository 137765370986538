import React from 'react';
import { CircularProgress } from '@mui/material';

const KjosSpiner = (props) => {
   const { size } = props;
  return (
    <div>
      <CircularProgress size={size} />
    </div>
  );
};

export default KjosSpiner;