import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import KjosChip from "./KjosChip";
import { FANCY_GREEN } from "config/color";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName?.indexOf(name) === -1
        ? theme?.typography?.fontWeightRegular
        : theme?.typography?.fontWeightMedium,
  };
}

export default function KjosSelect(props) {
  const theme = useTheme();
  var val
  return (
    <div>
      <FormControl sx={{ width: props?.width }}>
        <Select
        defaultValue={props?.defaultvalue}
          name={props.name}
          labelId={props.labelId}
          id={props?.id}
          label={props?.label}
          multiple
          value={props?.value}
          onChange={props?.onChange}
          input={<OutlinedInput id={props.selectId} />}
          renderValue={(selected) => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, overflow: "auto", height:props.height }}>
              {selected.map((value, index) => {
                val = value?.replace(/\d#/g, "")
                return (
                  <KjosChip
                    key={index}
                    label={val}
                    variant="outlined"
                    color={FANCY_GREEN}
                  />
                );
              })}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {props?.options?.map((name, index) => (
            <MenuItem key={index} value={name?.id+"#"+name?.label} style={getStyles(name, props?.value, theme)}>
              {name?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div> 
  );
}
