// export const RESET_PASSWORD_PATH = "/reset-password";
export const RESET_PASSWORD_PATH = "/auth/reset-password";

export const RECHERCHE_PATH = "/recherche";
export const CONTENU_ANONYME_PATH = "/contenu-anonyme";
export const CONTENU_PATH = "/contenu";
export const DESCRIPTION_PATH = "/description";
export const SIGNALEMENT_LIST_PATH = "/signalement-list";
export const SIGNALEMENT_LIST_TRAITE_PATH = "/signalement-list-traite";
export const SIGNALEMENT_LIST_ENCOUR_PATH = "/signalement-list-encour";
export const SETTING_PATH = "/setting";


export const SIGNIN_PATH = "/auth/login";
export const SIGNUP_PATH = "/auth/register";
export const DASHBOARD_PATH = "/";
export const PUBLIC_PATH = "" + window.location.pathname;
export const USER_EDIT_URL = "/user/edit";
export const USER_CREATE_URL = "/user/create";
export const ADMIN_EDIT_URL = "/administrateurs/edit";
export const HABILETE_EDIT_URL = "/habiletes/edit";
export const SIGNALEMENT_DETAIL_URL = "/signalement/detail";
export const SIGNALEMENTS_URL = "/signalements"; 
export const SIGNALEMENTS_CREATE_URL = "/signalements/create";
export const UTILISATEURS_URL = "/utilisateurs";
export const ADMINS_URL = "/administrateurs";
export const ADMINS_CREATE_URL = "/administrateurs/create";
export const WORKFLOW_URL = "/workflow";
export const MY_ACCOUNT_URL = "/account";
export const CMS_URL = "/cms";
export const PLAINTES_LIST_PATH = "/plaintes";
export const PLAINTES_DETAIL_PATH = "/plaintes/details";
export const ARTICLE_LIST_PATH = "/article";
export const ARTICLE_CREATE_PATH = "/article/create";
export const ARTICLE_EDIT_PATH = "/article/edit";
export const ARTICLE_DETAIL_PATH = "/article/detail";
export const RAPPORT_PATH = "/rapport";
export const RAPPORT_LIST_PATH = "/rapport/list";
export const RAPPORT_SEND = "/rapport/send";
export const RAPPORT_SEND_BY_FILTER = "/rapport/send_by_filter";