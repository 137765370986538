import { Box, Button, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult, setOpen } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import { CLASSE_SIGNALEMENT_CREATE_URL, ADMIN_COMMUNE_URL, USER_CLASSE_SIGNALEMENT_GET_URL } from "config/RoutesUrl";
import Dialog from "components/Dialog";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { setAlert, setCount, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import { CREATE_COLOR, DELETE_COLOR, FANCY_BLUE, FANCY_ORANGE, FANCY_PINK, UPDATE_COLOR } from "config/color";

const Communes = () => {
  const dispatch = useDispatch();
  const { getData, postData, putData, deleteData } = useHttp(); // get data from server
  const { postResult, getResult, deleteResult, editResult } = useSelector((state) => state.authentication); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const { page, rowsPerPage } = useSelector((state) => state.signalement);

  /* Table Header */
  const header = {
    libelle: "Libelle",
  };

  /* Dialog */
  const submitCallback = async (e) => {
    dispatch(setOpen(false));
    if (motif === "add") {
      if (libelleInputValue === "" || libelleInputValue === null || libelleInputValue === undefined) {
        dispatch(setAlert({ open: true, message: "Veuillez remplir tous les champs", color: "error" }));
        return;
      }

      const data = {
        libelle: libelleInputValue,
      };
      await postData(data, ADMIN_COMMUNE_URL); //add
    dispatch(setAlert({ open: true, message: "La commune a été ajouté avec succès", color: CREATE_COLOR }));

    } else if (motif === "edit") {
      if (itemsSelected[0] === "" || libelleInputValue === "" || itemsSelected[0] === null || libelleInputValue === null || itemsSelected[0] === undefined || libelleInputValue === undefined) {
        dispatch(setAlert({ open: true, message: "Veuillez remplir tous les champs", color: "error" }));
        return;
      }

      const data = {
        libelle: libelleInputValue,
        id: itemsSelected[0],
      };
      await putData(data, ADMIN_COMMUNE_URL + "/" + itemsSelected[0]); //edit
    dispatch(setAlert({ open: true, message: "La commune a été modifiée avec succès", color: UPDATE_COLOR }));

    }
  };

  const [libelleInputValue, setLibelleInputValue] = useState("");
  const handleOnChange = (e) => {
    setLibelleInputValue(e.target.value);
  };

  const body = <KjosInput onChange={handleOnChange} value={libelleInputValue} width="100%" focused={true} label="Libellé"></KjosInput>;
  /* End Dialog */

  /* Button control */
  const handleAdd = () => {
    setMotif("add");
    dispatch(setOpen(true));
  };

  const handleEdit = () => {
    setMotif("edit");
    if (itemsSelected.length == 1) {
      getResult?.data?.forEach((item) => {
        item.id == itemsSelected[0] ? setLibelleInputValue(item.libelle) : "";
        return false;
      });
      dispatch(setOpen(true));
    }
  };
  const handleDelete = async () => {
    await deleteData(itemsSelected, ADMIN_COMMUNE_URL); //edit
    dispatch(setAlert({ open: true, message: "La commune a été supprimé avec succès", color: DELETE_COLOR }));
  };
  const handleOnclose = () => {
    setOpenSnackBar(false);
    dispatch(setDeleteResult(null));
  };
  /* En Button control */

  useEffect(() => {
    async function fetchData() {
      const commune = await getData(ADMIN_COMMUNE_URL + `?page=${page + 1}&per_page=${rowsPerPage}`);
      if (commune?.error) {
        return;
      }
      dispatch(setCount(commune?.total));
      dispatch(setLoading(false)); // setLoading
    }
    fetchData(); // get data when component is mounted

    !postResult?.error && setLibelleInputValue(""); //if we submit without error reset form field
    deleteResult && setOpenSnackBar(true); //if we delete an item we open snackbar

    return () => {
      setOpenSnackBar(false);
    };
  }, [getData, postResult, deleteResult, editResult, dispatch, page, rowsPerPage]);

  useEffect(() => {}, [itemsSelected]);

  useEffect(() => {
    return () => {
      dispatch(setPage(0));
      dispatch(setRowPerpage(5));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, []);


  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
    <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography variant="h6">Gestion de localités</Typography>
            <div>
              <Stack direction={"row"} spacing={2}>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  onClick={handleAdd}
                  sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                >
                  Ajouter
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PencilIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="success"
                  onClick={handleEdit}
                  disabled={itemsSelected.length == 1 ? false : true}
                  sx={{ bgcolor: FANCY_PINK, color: "white" }}
                  >
                  Modifier
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <TrashIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  disabled={itemsSelected.length >= 1 ? false : true}
                  sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
                  >
                  Supprimer
                </Button>
              </Stack>
            </div>
          </Stack>

          <Stack>
            <KjosList2 data={getResult?.data} header={header} isCheckBox={true} title="Gestion de localités"  />
          </Stack>
        </Stack>
      </Container>

      <Dialog submitCallback={submitCallback} body={body} title="Ajouter" button="Valider" />

      {/* When we delete an item we open snackbar */}
      {/* <KjosSnacBar open={openSnackBar} onClose={handleOnclose} message={(deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ?? deleteResult?.message} autoHideDuration={6000} severity={deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"} /> */}
    </Box>
  );
};

export default Communes;
