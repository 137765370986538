// Get elapsted time from a date
export default  function getTimeElapsedString(date) {
   const now = new Date();
   const elapsedMilliseconds = now - date;
   const elapsedSeconds = Math.floor(elapsedMilliseconds / 1000);
   const elapsedMinutes = Math.floor(elapsedSeconds / 60);
   const elapsedHours = Math.floor(elapsedMinutes / 60);
   const elapsedDays = Math.floor(elapsedHours / 24);
   const elapsedWeeks = Math.floor(elapsedDays / 7);
   const elapsedMonths = Math.floor(elapsedDays / 30.44); // An average month length
   const elapsedYears = Math.floor(elapsedMonths / 12);

    if (elapsedYears > 0) {
    return `${elapsedYears} year${elapsedYears > 1 ? 's' : ''} `;
  } else if (elapsedMonths > 0) {
    return `${elapsedMonths} month${elapsedMonths > 1 ? 's' : ''} `;
  } else if (elapsedWeeks > 0) {
    return `${elapsedWeeks} week${elapsedWeeks > 1 ? 's' : ''} `;
  } else if (elapsedDays > 0) {
    return `${elapsedDays} day${elapsedDays > 1 ? 's' : ''} `;
  } else if (elapsedHours > 0) {
    return `${elapsedHours} hour${elapsedHours > 1 ? 's' : ''} `;
  } else if (elapsedMinutes > 0) {
    return `${elapsedMinutes} minute${elapsedMinutes > 1 ? 's' : ''}`
  } else  {
    return `${elapsedSeconds} second${elapsedSeconds > 1 ? 's' : ''}`
  }
  




 }
