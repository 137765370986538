import { useCallback } from "react";
import useHttp from "hooks/useHttp";
import {
  ADMIN_HABILETE_URL,
  ADMIN_HABILETE_PERMISSIONS_URL,
  ADMIN_PERMISSIONS_URL,
  ADMIN_CURRENT_PERMISSIONS_URL,
} from "config/RoutesUrl";
import translatePermission from "controller/translatePermission";
import { useDispatch } from "react-redux";
import { setCount } from "store/SignalementReducer";
export default function usePermission() {
  const { getData, postData } = useHttp();
  const dispatch = useDispatch();

  const getAllPermissions = useCallback(async () => {
    const request = await getData(ADMIN_PERMISSIONS_URL);
    const permission = request?.data;
    return permission;
  }, [getData]);

  const AssignPermissions = useCallback(
    async (data) => {


        // delete old permissions
        const deleteFormData = new FormData();
        deleteFormData.append("habilete_id", data?.habilete_id);
        deleteFormData.append("action", "delete");
        if(Array.isArray(data?.oldPermissions)){
          if(data?.oldPermissions?.length > 0){
            data?.oldPermissions.forEach((permission) => {
              deleteFormData.append("permissions[]", permission);
            });
          }else{
            deleteFormData.append("permissions[]", [""]);
          }
        }
        

        const request = await postData(deleteFormData, ADMIN_HABILETE_PERMISSIONS_URL);
        if (request.error) {
          return { error: true, message: "Erreur lors du traitement" };
        } else {
          // add news permissions
          const addFormData = new FormData();
          addFormData.append("habilete_id", data?.habilete_id);
          addFormData.append("action", "add");
          if(Array.isArray(data?.selectedPermissions)){
            if(data?.selectedPermissions?.length > 0){
              data?.selectedPermissions.forEach((permission) => {
                addFormData.append("permissions[]", permission);
              });
            }else{
              addFormData.append("permissions[]", [""]);
            }
          }
          const request2 = await postData(addFormData, ADMIN_HABILETE_PERMISSIONS_URL);
          return request2;

        }
    },
    [postData]
  );

  const getHabiletesPermissions = useCallback(async (page, rowsPerPage) => {
    const request = await getData(ADMIN_HABILETE_URL + '?page=' + page + '&per_page=' + rowsPerPage);
    if (request?.error) {
      return { error: true, message: request.message };
    } else {
      const permission = request?.data;
      dispatch(setCount(request?.total));
      if (Array.isArray(permission)) {
        const permissions = permission.map((item, index) => {
          return {
            libelle: item.libelle,
            label: item.libelle,
            value: item.id,
            id: item.id,
            permissions: item.permissions?.map(translatePermission)?.join(", "),
          };
        });
        return permissions;
      }else{
        return {
          error: false,
          data: [],
        }
      }
    }
  }, [dispatch, getData]);


  const showHabiletes = useCallback(async (habilete_id) => {
    const request = await getData(ADMIN_HABILETE_URL + "/" + habilete_id);
    return request
  }, [getData]);

  const hasPermission = useCallback(async (permission="") => {
      const permissions = await postData({ permission: permission }, ADMIN_CURRENT_PERMISSIONS_URL);
      return permissions;
  }, [postData])

  return {
    getAllPermissions,
    AssignPermissions,
    getHabiletesPermissions,
    showHabiletes,
    hasPermission
  };
}
