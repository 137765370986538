import { Box, Button, Chip, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult, setOpen } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import {
  ADMIN_INDEX_TRAITEMENT,
  ADMIN_SIGNALLEMENT_URL,
  ADMIN_TYPE_SIGNALEMENT_URL,
} from "config/RoutesUrl";
import Dialog from "components/Dialog";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import KjosRichText from "components/KjosRichText";
import getArrayItemsById from "controller/getArrayItemsById";
import KjosSvgIcon from "components/KjosSvgIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import KjosChip from "components/KjosChip";
import useSignalement from "hooks/useSignalement";
import useUser from "hooks/useUser";
import KjosSyncButton from "components/KjosSyncButton";
import { setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import { useNavigate } from "react-router-dom";
import { SIGNALEMENT_DETAIL_URL } from "config/PathUrl";
import { FANCY_BLUE, FANCY_GREEN } from "config/color";

const SignalementLayoutTermineAdmin = () => {
  const dispatch = useDispatch();
  const { getUser, getUserHabilitation } = useUser();
  const { createSignalement, updateSignalement, getAdminSignalement, querySignalement } = useSignalement(); // get data from server
  const { getData, postData, putData, deleteData, getOneData } = useHttp(); // get data from server
  const router = useNavigate();
  const { postResult, getResult, deleteResult, editResult, update } = useSelector(
    (state) => state.authentication
  ); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [confirmDelete, setConfirmDelete] = useState(false); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [signalements, setSignalements] = useState([]); // open snackbar
  const {page, rowsPerPage} = useSelector((state) => state.signalement);
  /* Table Header */
  const header = {
    // date: "Date",
    code: "Code",
    user: "Utilisateurs",
    type: "Type",
    localites: "Localités",
    workflow: "Statut",
  };

  /* Dialog */

  const handleSearch = async (e) => {
    const req = await querySignalement(e.target.value);
    const sign = await getAdminSignalement(page + 1, rowsPerPage, "order=desc&order_by=created_at&code=" + e.target.value);
    setSignalements(sign)
  };


  const submitDialogCallback = async (e) => {
    if (motif === "add") {
      const data = {
        libelle: libelleInputValue,
        type: typeInputValue,
        // url: urlInputValue,
      };
      await postData(data, ADMIN_SIGNALLEMENT_URL); //add
    } else if (motif === "edit") {
      const data = {
        libelle: libelleInputValue,
        // url: urlInputValue,
        type: typeInputValue,
        id: itemsSelected[0],
      };
      await putData(data, ADMIN_SIGNALLEMENT_URL + "/" + itemsSelected[0]); //edit
    }
  };

  const commentBody = <KjosRichText />;
  const tranfertBody = (
    <Stack
      direction={"row"}
      spacing={2}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{ width: "100%" }}
    >
      {" "}
      <KjosInput
        select={true}
        options={[{ label: "Groupe Juridique", value: "Groupe-Jurídique" }]}
        label="Router vers"
        width="80%"
      />
    </Stack>
  );
  const classifierBody = (
    <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ width: "100%" }}>
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PaperAirplaneIcon />
          </SvgIcon>
        }
        variant="contained"
        color="success"
      >
        Oui
      </Button>

      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <PaperAirplaneIcon />
          </SvgIcon>
        }
        variant="contained"
        color="error"
      >
        Non
      </Button>
    </Stack>
  );
  /* End Dialog */

  /* Button control */
  const handleAjouterCommentaire = () => {
    setDialogBody(commentBody);
    setDialogTitle("Ajouter un commentaire");
    dispatch(setOpen(true));
  };
  const hanldleTransferer = async () => {
    // 
    // 1- On recupère les infos du signalement
    const sign = await getOneData(itemsSelected[0], ADMIN_SIGNALLEMENT_URL);

    // 2- On recupère l'index position du signalement
    const signalement_position = sign?.data?.position;
    

    setDialogBody(tranfertBody);
    setDialogTitle("Router vers");
    dispatch(setOpen(true));
    // await deleteData(itemsSelected, ADMIN_SIGNALLEMENT_URL); //edit
  };
  const handleClasseSansSuite = () => {
    setDialogBody(classifierBody);
    setDialogTitle("Classe sans suite");
    dispatch(setOpen(true));
    // setOpenSnackBar(false);
    // dispatch(setDeleteResult(null));
  };
  const handledetail = () => {
    itemsSelected.length === 1 &&
    router(SIGNALEMENT_DETAIL_URL,{state: { id: itemsSelected[0] } });  };

  const handleCreate = () => {
    router("/signalements/create");
  };
  /* En Button control */

  /* Snackbar */
  const handleOnclose = () => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    async function fetchData() {
      const sign = await getAdminSignalement(page+1, rowsPerPage, "order=desc&order_by=created_at&statut=delivery");
      setSignalements(sign);
      
      dispatch(setLoading(false)); // setLoading
    }
    fetchData(); // get data when component is mounted
    // const interval = setInterval(fetchData(), 6000)
    return () => {
      setOpenSnackBar(false);
    };
  }, [dispatch, getAdminSignalement, page, rowsPerPage, update]);

  useEffect(() => {}, [itemsSelected]);
  useEffect(() => {
    return () => {
        dispatch(setPage(0));
        dispatch(setRowPerpage(5));
    };
  }, [dispatch]);
  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
            {/* <Typography variant="h6">Signalements non traités</Typography> */}
            <div>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Button
                  color="error"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  disabled={itemsSelected.length >= 1 && true}
                  variant="contained"
                  onClick={handleCreate}
                  sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                >
                  Créer
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <EyeIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={itemsSelected.length == 1 ? false : true}
                  onClick={handledetail}
                  sx={{ bgcolor: FANCY_GREEN, color: "white" }}
                >
                  Détails
                </Button>

                {/* <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PencilIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="success"
                  onClick={handleAjouterCommentaire}
                  disabled={itemsSelected.length == 1 ? false : true}
                >
                  Commenter
                </Button> */}

                {/* <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PaperAirplaneIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="warning"
                  onClick={hanldleTransferer}
                  disabled={itemsSelected.length >= 1 ? false : true}
                >
                  Transferer
                </Button> */}

                {/* <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PaperAirplaneIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="info"
                  onClick={hanldleTransferer}
                  disabled={itemsSelected.length >= 1 ? false : true}
                >
                  Historiques
                </Button> */}
                
                <KjosSyncButton />
                {/* <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <ArchiveBoxIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="error"
                  onClick={handleClasseSansSuite}
                  disabled={itemsSelected.length >= 1 ? false : true}
                >
                  Classer sans suite
                </Button> */}
              </Stack>
            </div>
          </Stack>

          <Stack>
            <KjosList2  data={signalements} header={header} isCheckBox={true} key={page} title="Terminé" onSearch={handleSearch} placeholder="Rechercher par le numéro de signalement" />
          </Stack>
        </Stack>
      </Container>

      <KjosDialog
        title={dialogTitle}
        body={dialogBody}
        button="Valider"
        submitCallback={submitDialogCallback}
      />
      {/* When we delete an item we open snackbar */}
      <KjosSnacBar
        open={openSnackBar}
        onClose={handleOnclose}
        message={
          (deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ??
          deleteResult?.message
        }
        autoHideDuration={6000}
        severity={
          deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"
        }
      />
    </Box>
  );
};

export default SignalementLayoutTermineAdmin;

export const Circuit = (props) => {
  const { classe, habiletes } = props;
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="body">{classe?.libelle}</Typography>{" "}
      <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
        {classe?.habiletes?.map((hab, index) => (
          <Stack key={index} direction={"row"} alignItems={"center"} spacing={1}>
            <Stack direction={"column"}>
              {hab?.map((item, i) => (

                <KjosChip key={i}
                  label={getArrayItemsById(item, habiletes)?.libelle}
                  size="small"
                  variant="outlined"
                  color="info"
                  />
                
              ))}
            </Stack>

            {index < classe?.habiletes?.length - 1 && <span>{"->"}</span>}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
