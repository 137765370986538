import { createSlice } from "@reduxjs/toolkit";

const initialState = {
     tableIsloading: false,
     itemsSelected: [],
     isItemsSelected: false
}
const tableListSlice = createSlice({
   name: "table",
   initialState,
   reducers: {
      setItemsSelected: (state, action) => {
         state.itemsSelected = action.payload
      },
      setTableLoading: (state, action) => {
         state.tableIsloading = action.payload
      },
      setIsItemsSelected: (state, action) => {
         state.isItemsSelected = action.payload
      },
   }
})
export const { setIsItemsSelected, setItemsSelected, setTableLoading } = tableListSlice.actions
export default tableListSlice.reducer