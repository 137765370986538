import KjosChip from "components/KjosChip";
import { FANCY_ORANGE } from "config/color";

export default function formatUserName(item, disign = true) {
  let user = "";
  if (item?.user) {
    if (item?.user?.nom) user = item.user.nom;
    if (item?.user?.prenom) user = user + " " + item.user.prenom;
  } else if (item?.admin) {
    if (item?.admin?.nom) user = item.admin.nom;
    if (item?.admin?.prenom) user = user + " " + item.admin.prenom;
  } else {
    user = "Anonyme";
  }

  return (
    disign?
    <>
      {item?.user ? (
        user
      ) : (
        <span>
          user <KjosChip label="Admin" color={FANCY_ORANGE} size="small" variant="contained" />
        </span>
      )}
    </>:
    user
  );
}
