/* eslint-disable react/jsx-max-props-per-line */
import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setOpen } from 'store/AuthenticationReducer';
import { Stack } from '@mui/system';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function KjosDialog(props) {
   var {title, body, button, submitCallback, fullScreen, dialogMessage, dialogMessageColor} = props
  const {open} = useSelector(state=>state.authentication)
  const dispatch = useDispatch()
  const handleSave = (e) => {
   submitCallback(e)
  }

  const handleClose = () => {
    dispatch(setOpen(false))
    title = ""
    body = ""
    button = ""
    dialogMessage = ""
    dialogMessageColor = ""
  }

  useEffect(()=>{ 
   return () => {
      dispatch(setOpen(false))
   }
  },[dispatch])

  return (
    <div>
      <BootstrapDialog
      fullScreen={fullScreen}
        onClose={(() => dispatch(setOpen(false)))}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: "100%" } } }}
      >
        <DialogTitle sx={{ mr:4 , p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
         {body}
        </DialogContent>
        
        <DialogActions>
          <Stack spacing={2} direction="row">
          <Typography variant="body2" sx={{ color: dialogMessageColor }} >
            {dialogMessage}
          </Typography>
          <Button autoFocus onClick={handleSave}>
            {button}
          </Button>
          </Stack>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}