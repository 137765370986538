import { useCallback, useMemo, useState } from 'react';
import { useSelection } from 'hooks/use-selection';
import { applyPagination } from 'utils/apply-pagination';
import { useDispatch, useSelector } from "react-redux";
import {setLoading, setPage, setRowPerpage} from "store/SignalementReducer"
import KjosTable  from 'components/kjosTable';

const now = new Date();


const useCustomers = (data = [], page, rowsPerPage) => {
  return useMemo(() => {
    return applyPagination(data, page, rowsPerPage);
  }, [data, page, rowsPerPage]);
};

const useCustomerIds = (customers) => {
  return useMemo(() => {
    return customers.map((customer) => customer.id);
  }, [customers]);
};

const KjosList2 = (props) => {
  const {data, header, isCheckBox, title, onSearch, placeholder} = props
  // const [page, setPage] = useState(0);
  const {page, rowsPerPage, count} = useSelector((state) => state.signalement);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  const customers = useCustomers(data, page, rowsPerPage);
  const customersIds = useCustomerIds(customers);
  const customersSelection = useSelection(customersIds);
  const dispatch = useDispatch()

  const handlePageChange = useCallback((event, value) => {
    dispatch(setPage(value))
    dispatch(setLoading(true))
    // setPage(value);
  }, [dispatch]);

  const handleRowsPerPageChange = useCallback((event) => {
    dispatch(setRowPerpage(event.target.value));
    dispatch(setLoading(true))
  }, [dispatch]);

  return (
    <>
      <KjosTable
        count={count}
        items={data ?? []}
        onDeselectAll={customersSelection.handleDeselectAll}
        onDeselectOne={customersSelection.handleDeselectOne}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
        onSelectAll={customersSelection.handleSelectAll}
        onSelectOne={customersSelection.handleSelectOne}
        page={page}
        rowsPerPage={rowsPerPage}
        selected={customersSelection.selected}
        header = {header}
        isCheckBox = {isCheckBox}
        title={title}
        onSearch={onSearch}
        placeholder={placeholder}
      />
    </>
  );
};

export default KjosList2;
