import { useCallback } from "react"
import useHttp from "hooks/useHttp"
import { ADMIN_DASHBOARD_URL, BASE_URL_API } from "config/RoutesUrl"

export default function useDashboard() {
   const {getData} = useHttp();
   const getDashoboard = useCallback(async (url) => {
      const dashboard = await getData(ADMIN_DASHBOARD_URL + "/" + url);
      return dashboard;
   }, [getData]);
   



   return { getDashoboard }
}