import { useNavigate } from "react-router-dom";;
import PropTypes from "prop-types";
import { Box, Divider, MenuItem, MenuList, Popover, SvgIcon, Typography } from "@mui/material";
import useDeconnect from "hooks/useDeconnect";
import { useSelector } from "react-redux";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import { Stack } from "@mui/system";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import ArrowLeftOnRectangleIcon from "@heroicons/react/24/solid/ArrowLeftOnRectangleIcon";
import useUser from "hooks/useUser";
import useHttp from "hooks/useHttp";
import { ADMIN_USER_URL } from "config/RoutesUrl";
import { useEffect } from "react";

const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const router = useNavigate();
  const {getUser, updateUser} = useUser();
  const {getOneData} = useHttp();

  const { deconnect } = useDeconnect();
  const { user } = useSelector((state) => state.authentication);
  useEffect(() => {
    getOneData(getUser('admin')?.user?.id, ADMIN_USER_URL)?.then((res) => {
      updateUser("admin",res?.data)
    })
  },[])
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "left",
        vertical: "bottom",
      }}
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 200 } }}
    >
      <Box
        sx={{
          py: 1.5,
          px: 2,
        }}
      >
        <Typography variant="overline">Compte</Typography>
        <Typography color="text.secondary" variant="body2">
          {(user?.nom + " " + user?.prenom).slice(0, 100)}
        </Typography>
      </Box>
      <Divider />
      <MenuList
        disablePadding
        dense
        sx={{
          p: "8px",
          "& > *": {
            borderRadius: 1,
          },
        }}
      >
        {/* <MenuItem onClick={() => router("/settings")}>
          <Stack direction={"row"} 
          spacing={2}>
            <SvgIcon fontSize="small">
              <CogIcon />
            </SvgIcon>
            <Typography>Paramètres</Typography>
          </Stack>
        </MenuItem> */}

        <MenuItem onClick={() => router("/account")}>
          <Stack direction={"row"} 
          spacing={2}>
            <SvgIcon fontSize="small">
              <UserIcon />
            </SvgIcon>
            <Typography>Mon compte</Typography>
          </Stack>
        </MenuItem>

        <MenuItem onClick={deconnect}>
          <Stack direction={"row"} 
          spacing={2}>
            <SvgIcon fontSize="small">
              <ArrowLeftOnRectangleIcon />
            </SvgIcon>
            <Typography>Déconnexion</Typography>
          </Stack>
        </MenuItem>

      </MenuList>
    </Popover>
  );
};

export default AccountPopover