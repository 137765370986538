export default function formatDate2(inputDate) {
  if (inputDate) {
    const date = new Date(inputDate);

    const options = { year: "numeric", month: "short", day: "numeric" };
    const formattedDate = new Intl.DateTimeFormat("fr-FR", options).format(date);

    return formattedDate;
  }
}
