import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import { useRef } from "react";
import KjosInputFile from "components/KjosInputFile";
import { avatar_anonyme, upload_file_icon } from "config/image";

export const AccountProfile = (props) => {
  const refImage = useRef();
  const fileListRef = useRef();
  const errorFileListRef = useRef();
  const {name} = props
  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="d-flex flex-column">
            <img
              src={avatar_anonyme}
              alt="avatar anonyme"
              style={{ width: "100%", aspectRatio: 5 / 3, objectFit: "cover" }}
              ref={refImage}
            />
            <div ref={errorFileListRef}></div>
            <div ref={fileListRef}></div>
            <KjosInputFile
              width="100%"
              height="2rem"
              // editIcon={<EditNoteIcon sx={{ color: "green" }} />}
              // deleteIcon={<HighlightOffIcon sx={{ color: "red" }} />}
              accept=".jpg, .png"
              id="evidence"
              name={name}
              fileListRef={fileListRef}
              errorFileListRef={errorFileListRef}
              refImage={refImage}
              imageMode={true}
              actionComponent={
                <Stack direction="row" justifyContent="center" sx={{ my: 3 }} spacing={3}>
                  <Button variant="text" size="large">
                    <label htmlFor={"evidence"}>Ajouter</label>
                  </Button>
                  <Button variant="text" size="large">
                    <label id="removePhoto">Retirer</label>
                  </Button>
                </Stack>
              }
            />
          </div>
          {/* <Typography
        gutterBottom
        variant="h5"
      >
        {user.name}
      </Typography> */}
          {/* <Typography
        color="text.secondary"
        variant="body2"
      >
        {user.city} {user.country}
      </Typography> */}
          {/* <Typography
        color="text.secondary"
        variant="body2"
      >
        {user.timezone}
      </Typography> */}
          <Typography mt={2} variant="body2">10Mo max (jpg, png)</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
