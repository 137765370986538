import KjosHttp from "controller/KjosHttp";
import { BASE_URL_API, IS_AUTH_URL } from "config/RoutesUrl";
import { useCallback, useEffect } from "react";
import { DASHBOARD_PATH, RESET_PASSWORD_PATH, SIGNIN_PATH, SIGNUP_PATH } from "config/PathUrl";
import useLocalSorage from "./useLocalSorage";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function useIsAuthenticate(
  url = BASE_URL_API + IS_AUTH_URL,
  item = "admin",
  route = { signin: SIGNIN_PATH, signup: SIGNUP_PATH, resetPassword: RESET_PASSWORD_PATH },
  dashboard = DASHBOARD_PATH
) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  // This function will check if user is authenticated
  const router = useNavigate(); // use router to redirect from a page to another

  const location = useLocation().pathname; // use location for the name of the route
  const [getLocalStorage, removeLocalStorage] = useLocalSorage(); // get local storage hook
  const isAuthUser = useCallback(async () => {
    const token = getLocalStorage(item)?.token; // get token
    const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
    try {
      const isAuth = await new KjosHttp().get(url, config); // ping the route
      if (
        isAuth?.error?.response.status === 401 &&
        location !== route.signin &&
        location !== route.signup &&
        location !== route.resetPassword
      ) {
        router(route); // redirect to signin
        // if there is an error remove the user
        removeLocalStorage(item); // remove the user
        setIsAuthenticated(false); // set auth to false if user is not connected
        router(route.signin); // redirect to signin
      } else if (
        isAuth?.status === 200 &&
        (location === route.signin || location === route.signup || location === route.resetPassword)
      ) {
        router(dashboard); // redirect to dashboard
        setIsAuthenticated(false); // set auth to true if user is connected
      } else {
        setIsAuthenticated(true); // set auth to true if user is connected
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
  }, [getLocalStorage, item, url, location, route, router, removeLocalStorage, dashboard]);
  return {isAuthenticated, isAuthUser};
}

export default useIsAuthenticate;
