import { configureStore } from '@reduxjs/toolkit'
import AuthenticationReducer from './AuthenticationReducer'
import TableListReducer from './TableListReducer'
import pingRouteReducer from './pingRouteReducer'
import SignalementReducer from './SignalementReducer'
  
export const store = configureStore({
  reducer: {
    authentication: AuthenticationReducer,
    pingroute: pingRouteReducer,
    table: TableListReducer,
    signalement: SignalementReducer
  },
})