import { Button, SvgIcon, Typography } from "@mui/material";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import KjosDialog2 from "components/KjosDialog2";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import useSignalement from "hooks/useSignalement";
import useHistorique from "hooks/useHistorique";
import useUser from "hooks/useUser";
import { useState } from "react";
import KjosRichText from "components/KjosRichText";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import { useEffect } from "react";
import { ANONYME_HISTORIQUE_URL } from "config/RoutesUrl";
import { setUpdate } from "store/AuthenticationReducer";
import useActionButton from "hooks/useActionButton";
import KjosSpiner from "components/KjosSpiner";
import { setAlert } from "store/SignalementReducer";
import { CREATE_COLOR, FANCY_BLUE, FANCY_GREEN } from "config/color";

export default function TransfererButton(props) {
  const { signalement, updateParent, signalementId } = props;
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const [dialogMessage, setDialogMessage] = useState(""); // open snackbar
  const [dialogMessageColor, setDialogMessageColor] = useState(""); // open snackbar
  const [spiner, setSpiner] = useState(""); // open snackbar
  const [motif, setMotif] = useState("");
  const { update } = useSelector((state) => state.authentication); // get result state
  const dispatch = useDispatch();
  const { endedSignalement, nexGroup, transfererSignalement, markAsDelivery } = useSignalement(); // get data from server
  const { setHistorique, getHistorique } = useHistorique();
  const { getUser, getUserHabilitation } = useUser();
  const { disableTransfertButton } = useActionButton();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [endTraitement, setEndTraitement] = useState(false);
  const [nextGroup, setNextGroup] = useState([]);
  const handleOnRichTextUpdate = (value) => {
    setComment(value);
  };

  const handleTransferer = async () => {
    const nextG = await nexGroup(signalementId);
    setNextGroup(nextG);

    const transfertBody = (
      <Stack direction={"column"} spacing={2}>
        <Typography variant="body2">Le signalement sera transféré au groupe suivant</Typography>
        <Typography variant="body2" sx={{ fontWeight: 600 }}>
          {nextG?.data?.classe} :{" "}
        </Typography>
        {/* list li of group */}
        <ul>
          {nextG?.data?.habiletes?.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Stack>
    );

    setDialogBody(transfertBody);
    setDialogTitle("Transfert");
    setMotif("tranferer");

    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const submitDialogCallback = async (e) => {
    setSpiner(<KjosSpiner size="1.5rem" />);
    if (motif === "tranferer") {
      const transfert = await transfererSignalement(signalementId);
      
      if (!transfert?.error) {
        setDisabled(true);

        // Historique
        const response = await setHistorique(
          "Pedding",
          signalement?.id,
          getUser("admin")?.user?.id,
          "L'administrateur " +
            getUser("admin")?.user?.nom +
            " à tranféré le signalement au groupe: " +
            nextGroup?.data?.classe +
            "(" +
            nextGroup?.data?.habiletes?.join(", ") +
            ")"
        );

        setSpiner("");

        setDialogMessage(transfert?.message);
        setDialogMessageColor("green");

        dispatch(setAlert({ open: true, message: "Le signalement a été transféré.", color: CREATE_COLOR }));
        setOpen(false);
      } else {
        setDialogMessage(transfert?.message);
        setDialogMessageColor("red");

        dispatch(setAlert({ open: true, message: "Le signalement n'a pas été transféré. Si le problème persiste contactez un administrateur.", color: "error" }));
        setOpen(false);
      }
    }
    dispatch(setUpdate());
    setOpen(false);
  };
  const handleDelivery = async () => {
    setLoading(true)
    const mark = markAsDelivery(signalementId);
    if(mark?.error){
      setLoading(false)
      setDialogMessage("Une erreur s'est produite");
      setDialogMessageColor("red");
      setOpen(true);
  
    }else{
      setLoading(false)
      setDialogMessage(mark?.message);
      setDialogMessageColor("green");
      setOpen(true);
    }
    dispatch(setUpdate());


  };

  useEffect(() => {
    disableTransfertButton(signalementId).then((resp) => {
      setDisabled(resp);
    });

    endedSignalement(signalementId).then((resp) => {
      setEndTraitement(resp);
    });
  }, [disableTransfertButton, endedSignalement, signalementId, update]);

  return (
    <>
      {/* Button */}
      {endTraitement ? (
        <Stack direction={"row"} spacing={2} alignItems={"center"}>
        <Button
          startIcon={
            <SvgIcon fontSize="small">
              <PaperAirplaneIcon />
            </SvgIcon>
          }
          disabled={disabled}
          variant="contained"
          color="info"
          onClick={handleDelivery}
          sx={{ bgcolor: FANCY_BLUE, color: "white" }}
          >
          Terminer
        </Button>
          {loading && <div>Loading...</div>}
        </Stack>
      ) : (
          <Button
            startIcon={
              <SvgIcon fontSize="small">
                <PaperAirplaneIcon />
              </SvgIcon>
            }
            variant="contained"
            color="info"
            onClick={handleTransferer}
            disabled={disabled}
            sx={{ bgcolor: FANCY_GREEN, color: "white" }}
            >
            Tranferer
          </Button>
      )}

      {/* Dialogue */}
      <KjosDialog2
        title={dialogTitle}
        body={dialogBody}
        button="Valider"
        submitCallback={submitDialogCallback}
        dialogMessage={dialogMessage}
        dialogMessageColor={dialogMessageColor}
        open={open}
        handleClose={handleDialogClose}
        spiner={spiner}
      />
    </>
  );
}
