import { Box, Button, Chip, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link, useLocation } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult, setOpen } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import { ADMIN_INDEX_TRAITEMENT, ADMIN_RAPPORT_URL, ADMIN_SIGNALLEMENT_QUERY_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_TYPE_SIGNALEMENT_URL } from "config/RoutesUrl";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import KjosRichText from "components/KjosRichText";
import getArrayItemsById from "controller/getArrayItemsById";
import KjosSvgIcon from "components/KjosSvgIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import KjosChip from "components/KjosChip";
import useSignalement from "hooks/useSignalement";
import useUser from "hooks/useUser";
import useHistorique from "hooks/useHistorique";
import KjosSyncButton from "components/KjosSyncButton";
import { setAlert, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import KjosAlert from "components/KjosAlert";
import { useNavigate } from "react-router-dom";
import { SIGNALEMENT_DETAIL_URL, RAPPORT_SEND_BY_FILTER } from "config/PathUrl";
import { FANCY_BLUE, FANCY_GREEN } from "config/color";
import KjosInput2 from "components/KjosInput2";
import KjosTitle from "components/KjosTitle";
import ReportIcon from "@mui/icons-material/Report";
import IosShareIcon from "@mui/icons-material/IosShare";
import isValidDate from "controller/isValidDate";
import { writeFile, utils } from "xlsx";
import KjosAuthorized from "components/KjosAuthorized";
import { Send } from "@mui/icons-material";
import KjosSelect5 from "components/KjosSelect5";
import useCommune from "hooks/useCommune";
import usePlainte from "hooks/usePlainte";

const headerPlainte = {
  // date: "Date",
  code: "Code",
  created_at: "Date des création",
  user: "Utilisateurs",
  objet_plainte: "objet de la plaintes",
  personne_en_charge: "Agent en charge",
  status: "Status",
};

const RapportList = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { getUser, getAllAdmins } = useUser();
  const { updateSignalement, getAllSignalement, getAllTypes, getAllSignalementRawData } = useSignalement(); // get data from server
  const { postData, putData, getOneData, getData } = useHttp(); // get data from server
  const [dialogMessage, setDialogMessage] = useState(""); // open snackbar
  const [dialogMessageColor, setDialogMessageColor] = useState(""); // open snackbar
  const { setHistorique } = useHistorique();
  const router = useNavigate();
  const { postResult, getResult, deleteResult, editResult, update } = useSelector((state) => state.authentication); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [confirmDelete, setConfirmDelete] = useState(false); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [signalements, setSignalements] = useState([]); // open snackbar
  const [plaintes, setPlaintes] = useState([]); // open snackbar
  const { page, rowsPerPage, alert } = useSelector((state) => state.signalement);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const filter = urlParams.get("filter");
  const [disabledEndDate, setdisabledEndDate] = useState(true);
  const [disabledCriteria, setdisabledCriteria] = useState(true);
  const [disableRepportButton, setdisableRepportButton] = useState(true);
  const [groupe_by_date_start, setGroupe_by_date_start] = useState("");
  const [criteria, setCriteria] = useState([]);
  const [criteriaLabel, setCriteriaLabel] = useState("");
  const [filterValue, setFilterValue] = useState("");
  const [groupe_by_date_end, setGroupe_by_date_end] = useState("");
  const [date_fin_min, setdate_fin_min] = useState("");
  const { json_to_sheet, book_append_sheet } = utils;
  const navigate = useNavigate();
  const { getAllCommune } = useCommune();
  const workbook = utils.book_new();
  const type = urlParams.get("type");
  const { getPlainte } = usePlainte();

  const handleDateDebut = async (e) => {
    setGroupe_by_date_start(e.target.value);
    if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
      return;
    }
    if (!isValidDate(e.target.value)) {
      return;
    }
    dispatch(setLoading(true)); // setLoading

    setdate_fin_min(e.target.value);
    const sign = await getAllSignalement(page + 1, rowsPerPage, "order=desc&order_by=created_at&groupe_by_date_start=" + e.target.value);

    if (Array.isArray(sign) && sign.length > 0) {
      setSignalements(sign);
      setdisabledEndDate(false);
      setdisableRepportButton(false);
    }

    dispatch(setLoading(false)); // setLoading
  };

  const handleDateFin = async (e) => {
    setGroupe_by_date_end(e.target.value);
    if (e.target.value === "" || e.target.value === null || e.target.value === undefined) {
      return;
    }
    if (!isValidDate(e.target.value)) {
      return;
    }
    dispatch(setLoading(true)); // setLoading

    const sign = await getAllSignalement(page + 1, rowsPerPage, "order=desc&order_by=created_at&groupe_by_date_start=" + groupe_by_date_start + "&groupe_by_date_end=" + e.target.value);
    setSignalements(sign);

    if (Array.isArray(sign) && sign.length === 0) {
      setdisableRepportButton(true);
    } else {
      setdisableRepportButton(false);
    }

    setdisabledCriteria(false);
    dispatch(setLoading(false)); // setLoading
  };

  const handleCriteriaChange = async (e) => {
    setFilterValue(e.target.value);

    dispatch(setLoading(true)); // setLoading
    if (type === "Plainte") {
      setPlaintes([]);
      const pl = await getPlainte(page + 1, rowsPerPage, "order=desc&order_by=created_at&filter=" + filter + "&filterValue=" + e.target.value + "&groupe_by_date_start=" + groupe_by_date_start + "&groupe_by_date_end=" + groupe_by_date_end);
      setPlaintes(pl);

      dispatch(setLoading(false)); // setLoading
      if (Array.isArray(pl) && pl.length === 0) {
        setdisableRepportButton(true);
      } else {
        setdisableRepportButton(false);
      }
    } else {
      setSignalements([]);
      const sign = await getAllSignalement(page + 1, rowsPerPage, "order=desc&order_by=created_at&filter=" + filter + "&filterValue=" + e.target.value + "&groupe_by_date_start=" + groupe_by_date_start + "&groupe_by_date_end=" + groupe_by_date_end);
      setSignalements(sign);

      dispatch(setLoading(false)); // setLoading
      if (Array.isArray(sign) && sign.length === 0) {
        setdisableRepportButton(true);
      } else {
        setdisableRepportButton(false);
      }
    }
  };

  /* Table Header */
  const header =
    type === "Plainte"
      ? {
          // date: "Date",
          code: "Code",
          date: "Date",
          user: "Utilisateurs",
          type: "Type",
          localites: "Localités",
          workflow: "STATUT",
        }
      : {
          code: "Code",
          date: "Date",
          user: "Utilisateurs",
          type: "Type",
          workflow: "STATUT",
        };
  const generateReport = async () => {
    let transformedArray = [];
    let sign;
    if (type === "Plainte") {
      sign = await getPlainte(null, null, "order=desc&order_by=created_at&filter=" + filter + "&filterValue=" + filterValue + "&groupe_by_date_start=" + groupe_by_date_start + "&groupe_by_date_end=" + groupe_by_date_end);
      transformedArray = sign.map((item) => ({
        CODE: item.code,
        DATE: item.date,
        UTILISATEUR: item.user.props.children,
        TYPE: item.type,
        LOCALITES: item.localites,
        STATUT: item.workflow.props.label,
      }));
    } else {
      sign = await getAllSignalement(null, null, "order=desc&order_by=created_at&filter=" + filter + "&filterValue=" + filterValue + "&groupe_by_date_start=" + groupe_by_date_start + "&groupe_by_date_end=" + groupe_by_date_end);

      transformedArray = sign.map((item) => ({
        CODE: item.code,
        DATE: item.date,
        UTILISATEUR: item.user.props.children,
        TYPE: item?.type,
        STATUT: item.workflow.props.label,
      }));
    }


    const worksheet = json_to_sheet(transformedArray);
    book_append_sheet(workbook, worksheet, "Sheet1");
    const xlsxFile = writeFile(workbook, filter + "-" + groupe_by_date_start + "-" + groupe_by_date_end + ".xlsx");

    const file = new Blob([xlsxFile], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

    const formData = new FormData();
    formData.append("file", file);
    const res = await postData(formData, ADMIN_RAPPORT_URL);
  };

  const handleCreate = () => {
    router("/signalements/create");
  };
  /* En Button control */

  /* Snackbar */
  const handleOnclose = () => {
    setOpenSnackBar(false);
  };

  const handleSendMail = () => {
    navigate(RAPPORT_SEND_BY_FILTER, { state: { filter: filter, filterValue: filterValue, groupe_by_date_start: groupe_by_date_start, groupe_by_date_end: groupe_by_date_end, type: type, nextPage: true } });
  };

  useEffect(() => {
    if (filter === "groupe_by_localites") {
      setCriteriaLabel("Selon la localité");
      getAllCommune().then((data) => {
        setCriteria(data);
      });
    } else if (filter === "groupe_by_type_signalement") {
      setCriteriaLabel("Selon le type");
      getAllTypes().then((data) => {
        setCriteria(data);
      });
    } else if (filter === "groupe_by_status") {
      setCriteriaLabel("Selon le status");

      if (type === "Plainte") {
        setCriteria([
          { id: null, label: "Nouveau" },
          { id: "take_charge", label: "Pris en charge" },
          { id: "without_continuation", label: "Sans suite" },
        ]);
      } else {
        setCriteria([
          { id: "new", label: "Nouveau" },
          { id: "open", label: "Ouvert" },
          { id: "pendding", label: "En cours" },
          { id: "without_continuation", label: "Sans suite" },
          { id: "delivery", label: "Terminé" },
        ]);
      }
    } else if (filter === "groupe_by_user") {
      setCriteriaLabel("Selon les Administrateurs");
      getAllAdmins().then((data) => {
        setCriteria(data);
      });
    }

    dispatch(setLoading(true)); // setLoading
    async function fetchData() {
      if (type === "Plainte") {
        const pl = await getPlainte(page + 1, rowsPerPage, "order=desc&order_by=created_at");
        setPlaintes(pl);
      } else {
        const sign = await getAllSignalement(page + 1, rowsPerPage);
        setSignalements(sign);
      }

      dispatch(setLoading(false)); // setLoading
    }
    fetchData(); // get data when component is mounted
    // const interval = setInterval(fetchData(), 6000)
    return () => {
      setOpenSnackBar(false);
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch, getAllSignalement, page, rowsPerPage, update]);

  useEffect(() => {}, [itemsSelected]);

  useEffect(() => {
    return () => {
      dispatch(setPage(0));
      dispatch(setRowPerpage(5));
    };
  }, [dispatch]);

  return (
    <KjosAuthorized permission="report_management">
      <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
        <KjosAlert label={alert.message} />
        <Stack sx={{ px: 5 }}>
          <KjosTitle icon={<ReportIcon />} title="Rapports" subtitle="Générer le rapport" iconBgColor={FANCY_BLUE} />
        </Stack>
        <br />
        <Container maxWidth={"xl"}>
          <Stack spacing={3} direction={"column"}>
            <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"flex-end"}>
              {/* <Typography variant="h6">Signalements non traités</Typography> */}
              <Stack direction={{ xs: "column", md: "row" }} alignItems={"flex-end"} spacing={2}>
                <KjosInput2 type="date" id="groupe_by_date_start" label="Date début" name="groupe_by_date_start" width={"100%"} max={new Date().toISOString().split("T")[0]} value={groupe_by_date_start} onChange={handleDateDebut} />

                <KjosInput2 type="date" id="groupe_by_date_end" label="Date fin" name="groupe_by_date_end" width={"100%"} min={date_fin_min} max={new Date().toISOString().split("T")[0]} disabled={disabledEndDate} value={groupe_by_date_end} onChange={handleDateFin} />

                <div style={{ width: "15rem" }}>
                  <KjosSelect5 id="criteria" label={criteriaLabel} onChange={handleCriteriaChange} disabled={disabledCriteria} name="criteria" options={criteria ?? []} />
                </div>

                {/* <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PaperAirplaneIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="info"
                  disabled={itemsSelected.length >= 1 ? false : true}
                >
                  Historiques
                </Button> */}

                {/* <KjosSyncButton /> */}
              </Stack>
              <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <IosShareIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={disableRepportButton}
                  onClick={generateReport}
                  sx={{ bgcolor: FANCY_GREEN, color: "white", height: "40px" }}
                >
                  Générer le Rapport
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <Send />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={disableRepportButton}
                  onClick={handleSendMail}
                  sx={{ bgcolor: FANCY_GREEN, color: "white", height: "40px" }}
                >
                  Envoyer par mail
                </Button>
              </Stack>
            </Stack>

            <Stack>{type === "Plainte" ? <KjosList2 data={plaintes} header={headerPlainte} isCheckBox={false} key={page} /> : <KjosList2 data={signalements} header={header} isCheckBox={false} key={page} />}</Stack>
          </Stack>
        </Container>

        {/* When we delete an item we open snackbar */}
        <KjosSnacBar open={openSnackBar} onClose={handleOnclose} message={(deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ?? deleteResult?.message} autoHideDuration={6000} severity={deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"} />
      </Box>
    </KjosAuthorized>
  );
};

export default RapportList;

export const Circuit = (props) => {
  const { classe, habiletes } = props;
  return (
    <Stack direction={"column"} justifyContent={"center"} alignContent={"center"} alignItems={"center"}>
      <Typography variant="body">{classe?.libelle}</Typography>{" "}
      <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
        {classe?.habiletes?.map((hab, index) => (
          <Stack key={index} direction={"row"} alignItems={"center"} spacing={1}>
            <Stack direction={"column"}>
              {hab?.map((item, i) => (
                <KjosChip key={i} label={getArrayItemsById(item, habiletes)?.libelle} size="small" variant="outlined" color="info" />
              ))}
            </Stack>

            {index < classe?.habiletes?.length - 1 && <span>{"->"}</span>}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
