import { useCallback } from "react";
import { ADMIN_COMMENTAIRE_URL, ANONYME_HISTORIQUE_URL, USER_HISTORIQUE_URL } from "config/RoutesUrl";
import useHttp from "./useHttp";
import getCurrentDate from "controller/getCurrentDate";

export default function useCommentaire() {
  const { postData, getData } = useHttp();

  const setCommentaire = useCallback(
    async (data, url = ADMIN_COMMENTAIRE_URL) => {
      
      const commentaire = await postData(data, url);
      return commentaire;
    },
    [postData]
  );

  const getCommentaire = useCallback(
    async (signalement_id, url = ADMIN_COMMENTAIRE_URL, query = null) => {
      if (query) {
        const historique = await getData(url + "?signalement_id=" + signalement_id + "&" + query);
        return historique;
      } else {
        const historique = await getData(url + "?signalement_id=" + signalement_id);
        return historique;
      }
    },
    [getData]
  );

  return { setCommentaire, getCommentaire };
}
